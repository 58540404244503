import React, {useContext} from "react";
import { UserContext } from '../Context/UserContext';

function LogoutButton() {
    const {logout} = useContext(UserContext);

    function handleUserLogout() {
        fetch('/logout', {
            method: 'DELETE',
            headers: { "Content-Type": "application/json" },
        })
            .then(() => {
                logout()
            })
    }

    return (
        <div className="logoutButtonContainer">
            <p onClick={handleUserLogout}>Logout</p>
        </div>
    )
}

export default LogoutButton
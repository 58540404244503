import React from "react";

function PrivacyPolicy() {
  return (
    <div className="privacy">
        <h1>Privacy Policy</h1>
        <p>Last Modified May 21, 2024</p>
        <p>Protecting your privacy is an important priority to Batter's Box Media Inc., an Illinois corporation (collectively, “us,” “we” or “The Batters Box”), and we are committed to maintaining strong and meaningful privacy protections.  The privacy of your information is a significant responsibility and we value the trust you place in us.  The Batters Box is respectful of data privacy and strives to adopt best practices in compliance with applicable privacy laws and regulations, including, without limitation, the European Union General Data Protection Regulation (“GDPR”).</p>
        <p> This Policy applies to visitors of our website, <a href="https://www.batters-box.com/">www.batters-box.com</a>, and any related or sub-website pages (collectively, the “Site”), and social media accounts, pages, and other applications (“Social Media Accounts”) any subscribers of our publications, newsletters, notifications, or memberships, any contributors of content to the Site or Social Media Accounts, any users, viewers, subscribers, or purchasers of our services or products and any of our other customers, members, and partners (all of whom may be referred to as “you” in this Policy). The Site, our products, services, and communications promoted, offered, or accessible on the Site, the Social Media Accounts, other The Batters Box-related websites or applications, and any other The Batters Box product or services related to the foregoing are collectively referred to herein as the “Services.” </p>
        <p> If you are a European Union (“EU”) resident, please note that there are a number of provisions in this Policy that apply uniquely to you (which have been italicized for your convenience). </p>
        <p>We offer a wide range of Services, and sometimes additional terms of The Batters Box may apply. When you use certain Services, you may also be subject to additional guidelines, terms, and agreements of The Batters Box that are applicable to such Services, which are incorporated herein by reference (“Other Agreements”). If this Policy is inconsistent with the Other Agreements, those Other Agreements will control.
        </p>
        <p> BY VIEWING, USING, REGISTERING FOR, OR ACCESSING THE SERVICES, YOU ACCEPT AND AGREE TO BE BOUND AND ABIDE BY THIS POLICY. PLEASE READ THIS POLICY AS WELL AS THE TERMS OF SERVICE AND OTHER AGREEMENTS CAREFULLY.</p>
        <p>YOU MAY NOT ACCESS OR USE THE SERVICES IF YOU (A) DO NOT AGREE TO THIS POLICY,
        THE TERMS OF SERVICE, AND ANY APPLICABLE OTHER AGREEMENTS, (B) ARE NOT AT LEAST 18 YEARS OLD,
        OR (C) ARE PROHIBITED FROM ACCESSING OR USING THE SERVICES BY APPLICABLE LAW.</p>
        <p> YOU HEREBY REPRESENT THAT YOU (A) HAVE READ, UNDERSTOOD AND ACCEPTED THIS POLICY AND ANY APPLICABLE OTHER AGREEMENTS, (B) WILL BE FINANCIALLY RESPONSIBLE FOR YOUR ACCESS TO OR USE OF THE SERVICES, (C) ARE AT LEAST 18 YEARS OLD, AND (D) MAY OTHERWISE ENTER INTO AND FORM BINDING CONTRACTS UNDER APPLICABLE LAW.</p>
        <p>IF YOU CHOOSE NOT TO ACCEPT THIS POLICY, DO NOT VIEW, REGISTER FOR, ACCESS OR USE THE SERVICES.</p>
        <h2>WHAT TYPES OF INFORMATION WE COLLECT ABOUT YOU</h2>
        <p>We collect information about you when you communicate with us and/or when you visit, use, or download anything from the Services, including, without limitation, when you register for an account through the Site, access the Site, sign up for or request our publications, newsletters, or notifications, subscribe to our membership, participate in a survey and/or offer, subscribe, register for an account, or post content through, use or purchase any of the Services, publish, or otherwise take action on or through the Services.</p>
        <h2>Information You Give Us</h2>
        <p>The personal information we collect includes information that is publically available or that you provide us, which may consist of your name, postal address, telephone number, email address, date of birth, demographics, activities, location information, device information, usage details, and payment information and other reasonably identifying information relevant to the Services, including any information that is collected by Google Analytics.  Personal information does not include “de-identified,” “anonymous” or “aggregate information,” which are not associated with a specific person or entity.</p>
        <p>You may give us personal information in a variety of ways, including, without limitation, when you:</p>
        <ol type="a">
            <li>Register for premium access, including, without limitation, any information you provide us in connection with our registration process, surveys, or when you report a problem with the Services; </li>
            <li>Purchase, access, or use any of the Services;</li>
            <li>Subscribe or sign up for premium access or otherwise receive our publications, email communications, and feeds from blogs and social media, link to our social media platforms, and use the Services (e.g., addresses for email and other electronic communications, log-in information, social media account information);</li>
            <li>Provide, post, or upload personal information to or through the Services;</li>
            <li>Communicate with us (e.g., name, address, email address and records of your correspondence) by emailing us at contactbattersbox@gmail.com (please note, you should not send confidential or proprietary information to us via email, other electronic communication, or via a post to a public platform); or</li>
            <li>Download content from the Services and when you otherwise knowingly provide personal information to us.</li>
        </ol>
        <h3>Information from Your Service Use</h3>
        <p>We also may collect information about you when you visit or otherwise use the Services, such as when you view or click on content or ads, perform a search or view articles, and details of transactions you carry out through the Site and any financial information you provide.</p>
        <h3>Information We Automatically Collect</h3>
        <p>When you access and use the Services, we may also collect information about you and the device you used to access any of the Services or to transmit information (such as hardware model, operating system version, unique device identifiers, and mobile network information including phone number), log information (Internet Protocol address, browser type, system activity, hardware settings, browser settings and language, date and time of access, last URL visited, cookies, how you used any of the Services), cookies that may uniquely identify your browser, and, if you enable any location detection, we may collect information about your actual location (such as GPS signals and other geolocation data), and, if you enable access to stored information and files (such as photos, audio, video clips, personal contacts, and address book information), we may collect the metadata and other information associated with those files. This Policy covers the use of cookies on or through the Services and does not cover the use of cookies by any third parties, including, without limitation, advertisers or vendors.</p>
        <p>We may link information we automatically collect with personal information for any lawful purpose, such as to improve and analyze the use of the Services, to improve the visitor or premium access experience, to deliver Services, to communicate with you, to monitor the security and integrity of the Site, and to analyze our operations. </p>
        <p>We may also send a “cookie,” “pixel tag,” clear gif or web beacons to your device that contains identification unique to the device you are using. The information we receive through cookies, web beacons, and similar technologies may enable us to recognize users across devices, such as smartphones, computers, tablets, or related browsers. We may use these technologies to collect information about your activities over time and across third party websites, apps, or other online services (behavioral tracking). Depending upon your device or computer, you may be able to set your browser(s) to reject cookies or delete cookies, but that may result in the loss of some functionality of the Services.</p>
        <p>If you do not want us to send you promotional content, you can opt-out by following the instructions provided in the email or by contacting us directly at contactbattersbox@gmail.com. We currently do not respond to “do not track” signals from web browsers or other mechanisms that provide users with the ability to exercise choice regarding the collection of personal information. If you do not want us to collect and/or track this type of information, including, without limitation, location information and the use of related tracking technologies, do not access or use any of the Services.</p>
        <p>We do not control third parties' collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“NAI”) on the NAI's website.</p>
        <em>If you are an EU resident,to the extent that any cookie can uniquely identify a computer, mobile device or tablet, or the person using such device, under the GDPR, this is personal data to which, if collected by us, this Policy applies.</em>
        <h3>Other Information</h3>
        <p>As we introduce new features to any of the Services, we may require or gain the ability to collect new information.  If we collect materially different personal information, we will notify you and/or modify this Policy.</p>
        <h2>HOW WE USE AND SHARE YOUR INFORMATION</h2>
        <p>We use your personal information for a variety of purposes, including, without limitation, to:</p>
        <ol type="a">
            <li>provide you with access to or use of any of the Services;</li>
            <li>Deliver and confirm products or services you obtain from us;</li>
            <li>Charge you for any products or services ordered or purchased by you (if applicable);</li>
            <li>Explore and evaluate various articles or other content on the Site;</li>
            <li>Render any other services you have requested;</li>
            <li>Verify your identity and maintain a record of your transactions and interactions with us;</li>
            <li>Provide customer and technical services to you;</li>
            <li>Investigate, respond to, and resolve any complaints and service issues;</li>
            <li>Make internal business decisions about current and future product and service offerings;</li>
            <li>Communicate with you regarding news, events, ideas, related topics, special offers, promotions, new services, and updates;</li>
            <li>Conduct research and development to provide you and others with a better, more intuitive and personalized experience, and drive premium access growth and engagement of any of the Services, including to estimate our audience size and usage patterns, store information about your preferences and recognize you when you use the Services;</li>
            <li>Create, target, or measure advertisements;</li>
            <li>Protect our rights, interests, safety and property and that of our customers, service providers and other third parties; or</li>
            <li>Comply with law or as required for legal purposes.</li>
        </ol>
        <p>We use personal information to promote security, investigate violations of our agreements (including this Policy) and/or attempts to harm our users and visitors, and verify proper conduct using any of the Services. We may also use personal information for investigations or prevention of fraud or network abuse.</p>
        <p>Third parties may use non-personal information in order to display advertising that reflects the interests and preferences of our user community.</p>
        <em>The GDPR requires data collectors, such as The Batters Box, to have a legal basis to use the personal data of EU residents. Therefore, this section shall apply to any such personal data collected by us. We use the personal data that you provide to supply you with high-quality products, content and services as you request, to send important notices, and for internal purposes such as auditing, data analysis, and research to provide you with the information you need to make the most informed decisions. We may also use your personal data to offer you products, content or services that may be of interest to you, based upon your interests or the preferences you shared with us, and may share your personal data with third party sponsors of content, events, and other services or offers.  The legal basis for processing your personal data is your consent. In certain circumstances we may otherwise process your personal data if we have a legitimate interest in doing so and we are not infringing any of your rights and freedoms. When we process your personal data for our legitimate interests, we will consider and balance any potential impact on you and your rights under data protection and any other relevant law. Our legitimate business interests do not override your interests. We will not use your personal data in circumstances where your rights and freedoms override our legitimate interests, unless we have your consent or are otherwise required or permitted to by law.</em>
        <h2>WHEN WE SHARE YOUR INFORMATION</h2>
        <h3>De-identified Information Sharing</h3>
        <p>We may share information that is de-identified or in an aggregated form that does not directly identify you.</p>
        <h3>Third Party and Plug-in Sharing</h3>
        <p>Some content or applications, including advertisements, on the Site and throughout the other Services are served by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use tracking technologies to collect information about you when you use our Services. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, applications and other online service websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content when you use any of the Services. For example, we may enable social network plug-ins, whereby those social media providers embed content and tools on the Site (i.e., plug-ins), which may collect personal information directly from you in connection with your interaction with such content and tools. Such providers' use of the information is subject to their own privacy policies, which we recommend you review. We are not responsible for such providers' privacy practices, which are not covered by this Policy.</p>
        <h3>Affiliated Third Party, Successor, or Assignee Sharing</h3>
        <p>We may share any of the information we collect with affiliated companies under common ownership or control, such as companies that are part of The Batters Box family of companies, and any successors and assignees of The Batters Box. We share information with our affiliates so that they may provide you with information regarding products and services that may be of use to you, and for any other use set forth in this Policy. If we choose to share information we collect in this fashion, we will require our affiliates to honor this Policy.</p>
        <h3>Cross-border data transfers.</h3>
        <p>The data that we collect from you may be transferred to, and stored at, a destination outside your country, and outside the European Economic Area (“EEA”), but only where we reasonably believe that such country or territory has an adequate level of protection for the rights and freedoms of data subjects in relation to the processing of personal data. By submitting your personal data, you agree to any such cross-border transfer, storing or processing. We are not responsible for such country's or territory's privacy practices, which are not covered by this Policy.</p>
        <h3>Unaffiliated Third Party Sharing</h3>
        <p>We do not sell, license, rent or otherwise provide your personal information to unaffiliated third parties except as follows:</p>
        <ol type="a">
            <li>With your consent, which may be obtained in writing, online through “click-through” agreements, by registering for premium access on the Site, orally on the phone, or implicitly, for example, when you request more information to be sent to your home or business address, you implicitly consented to our disclosure of your name and address to a third party courier to complete delivery;</li>
            <li>To our third-party vendors and partners who complete transactions or perform services on our behalf (for example, credit/debit card processing, development, fraud protection, billing, shipping, customer service, auditing and marketing);</li>
            <li>To verify the information received from you in connection with the Services;</li>
            <li>Pursuant to a change in our business structure, such as a merger, acquisition, joint venture, corporate reorganization, financing or sale of company assets, or in the unlikely event of insolvency, bankruptcy or receivership;</li>
            <li>As required by law or other legal process, such as a court order, summons, subpoena, and the like;</li>
            <li>To investigate, prevent, or take action regarding suspected or actual illegal activities or to assist government enforcement agencies, enforce our rights in connection with our agreements with you, or investigate and defend ourselves against any third-party claims or allegations;</li>
            <li>To exercise or protect our rights or interests, property or safety or that of others;</li>
            <li>To protect users of any of the Services from fraudulent, abusive or unlawful use of the Services and/or to protect the security or integrity of any of the Services (such as by sharing with companies facing similar threats);</li>
            <li>To facilitate or verify the appropriate calculation of taxes, fees, or other obligations due to a local, state, or federal government; or</li>
            <li>In an emergency situation.</li>
        </ol>
        <em>If you are an EU resident, we will only share your personal data with unaffiliated third parties, with your consent.  If we share your personal data to our affiliates or with any other third party as described in this Policy and your personal data will be transferred to a state which is not a member state of either the EU or the EEA, or deemed adequate by the European Commission, we (as a data controller/data exporter) will only conduct such transfer (to a data processor/data importer) if there are suitable safeguards in place, such as binding corporate rules, standard contractual clauses, approved Codes of Conduct, or approved certification mechanisms.  For more information, please contact our Data Protection Officer (see information at the bottom of this Policy).</em>
        <h2>HOW WE PROTECT YOUR INFORMATION</h2>
        <p>We use a variety of physical, electronic and procedural safeguards to protect your personal information from loss, misuse and unauthorized access, disclosure, alteration and destruction while it is under our control. Under our practices and policies, access to sensitive personally identifiable information is authorized only for those who have a business need for such access. Although we work hard to protect personal information that we collect and store, no data security measures are 100% secure and we cannot guarantee that our safeguards will prevent every unauthorized attempt to access, use, alter, destroy, or disclose personal information. We maintain security and incident response plans to handle incidents involving unauthorized access to private information we collect or store.  We retain information only as long as reasonably necessary for business, accounting, tax or legal purposes.  When we dispose of personal information, we use reasonable procedures designed to erase or render it unreadable (for example, shredding documents and wiping electronic media). If you become aware of a security issue, please contact us at the email address provided at the bottom of this Policy.  We will work with you to address any problems.</p>
        <h2>YOUR CONTROL OVER PERSONAL INFORMATION</h2>
        <h3>Information Provided to Us</h3>
        <p>You play an important role in ensuring the security of your personal information. We encourage you to use safeguards to protect your information. If you have provided personal information to us and wish to delete, change, or correct, or limit or restrict our use of such personal information, you can:</p>
        <ol type="a">
            <li>Ask us to erase or delete all or some of your personal information (e.g., if it is no longer necessary to provide the Services to you);</li>
            <li>Edit some of your personal information through your account, if applicable, or ask us to change, update, or fix your information in certain cases, particularly if it's inaccurate;</li>
            <li>Ask us to stop using all or some of your personal information (e.g., if we have no legal right to keep using it) or to limit our use of it (e.g., if your personal information is inaccurate or unlawfully held); and</li>
            <li>Ask us for a copy of your personal information.</li>
        </ol>
        <p>For any of the above requests, you will need to send us a written request (email) in accordance with our notice provisions below. We are not required to alter or delete information stored for purposes of backup or disaster recovery (or where such action may not be technologically possible).</p>
        <em>If you are an EU resident, under the GDPR, you may, among other things, send us a request to (a) confirm that we are processing your personal data, or to access, update or correct the personal data we hold about you, or to obtain a copy to reuse for your own purposes, (b) erase your personal data, (c) opt-out of marketing communications from us or (d) restrict our processing of your personal data. We kindly ask you to send such concerns (or objection) in written form stating your name, address and phone number, so that we can confirm your identity. If you inform us that you want your personal data erased (if you are an EU resident), or that you no longer wish for us to communicate with you for marketing purposes, or to otherwise restrict our processing of your personal data, we may retain some basic information in order to avoid sending you unwanted materials in the future, and to keep a record of your request and our response.</em>
        <h2>No Control over Others</h2>
        <p>You should be aware that if you voluntarily disclose personal information online in a public area of the Services, that information may be collected and used by others. We do not control the actions of our visitors and other users of the Services. If you disclose personal information in a publicly accessible place in the Services, you do so at your own risk and you understand that your submitted information may be collected and used by others to send you unsolicited messages, advertisements, or for other purposes. The foregoing does not waive our right to enforce any legal and contractual rights it may have against those who scrape, crawl, or extract data from the Services, including any such restrictions that may be set forth in our other posted terms and policies.</p>
        <h2>Opt Out</h2>
        <p>If you register for the Services, from time to time, we may email you with informative messages about the Services or third party products and services we believe may be of interest to you. If you do not want to receive these types of emails please let us know by sending us written notice in accordance with our notice provisions below.  We may also provide opt-out information in its marketing communications via an “unsubscribe” link in communication emails, which would unsubscribe you from any further communications from us that are unrelated to one of the Services we are providing or are required to provide to you. Please note that it may take us some time to process your requests, consistent with applicable law. Please note that you do not have the ability to opt-out of non-marketing communications that we send you in connection with the use of the Services, such as when you have engaged in transactions with us.</p>
        <h2>Account Closure</h2>
        <p>We generally attempt to delete closed account information within 30 days of account closure, except as noted below. We retain your personal information even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse, enforce our agreements with you, or fulfill your request to “unsubscribe” from further messages from us. We will retain de-personalized information after your account has been closed. Information you have shared with others on or through the Services may remain visible after you closed your account or deleted the information from your own profile, and we do not control information that other users copied out of the Site or Social Media Accounts.  Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache. </p>
        <h2>OTHER INFORMATION YOU SHOULD KNOW</h2>
        <h3>Information about Children</h3>
        <p>We are committed to protecting the privacy of children. The Services we control are not designed or intended to attract or target children under the age of 13. We do not collect personal information from any person whom we actually know is under the age of 13.</p>
        <h3>California Privacy Rights</h3>
        <p>California Civil Code Section 1798 entitles California customers to request information concerning whether a business has disclosed personal information to any third parties for the third parties' direct marketing purposes. As stated in this Policy, we will not sell or share your personal information with non-affiliated companies for their direct marketing purposes without your consent (and as otherwise set forth in this Policy). California customers who wish to request further information about our compliance with this law or have questions or concerns about our privacy practices and policies may contact us as specified in the notices or contact information sections provided below.</p>
        <h3>Other State Privacy Rights</h3>
        <p>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information.  California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Montana, Oregon, Tennessee, Texas, Utah, and Virginia provide their state residents with rights to confirm whether we process their personal information, access and delete certain personal information, correct inaccuracies in their personal information, taking into account the information's nature processing purpose (excluding Iowa and Utah), data portability, opt-out of certain personal data processing (such as for targeted advertising (excluding Iowa), sales, and profiling in furtherance of decisions that produce legal or similarly significant effects (excluding Iowa and Utah)), and either limit (opt-out of) or require consent to process sensitive personal data. The exact scope of these rights may vary by state. To exercise any of these rights, please contact us as specified in the notices or contact information sections provided below.</p>
        <h3>Links to Other Websites or Applications</h3>
        <p>On or through the Services, we may provide links to third party websites. We are not responsible for the content or information collection practices of those sites, including their privacy policies. Please review their privacy policies and content or information collection practices before using such third party applications or visiting their sites.</p>
        <h3>Anonymous or De-identified Data</h3>
        <p>We may anonymize and/or de-identify information collected by us via the Services or other means so that the information does not identify you. Notwithstanding any other provision in this Policy, our use and disclosure of aggregated and/or de-identified information is not subject to any restrictions under this Policy, and we may disclose it to others without limitation for any purpose.</p>
        <h3>Notice to Non-U.S. Users</h3>
        <p>Our computer systems are currently based in the United States, so your personal data will be processed by us in the United States where data protection and privacy regulations may not offer the same level of protection as in other parts of the world, such as the EU. If you create an account with the Site as a visitor from outside the United States, by using the Services, you agree to this Policy and you consent to the transfer of all such information to the United States, which may not offer an equivalent level of protection of that required in the EU or certain other countries, and to the processing of that information as described in this Policy.</p>
        <h3>Social Media Accounts</h3>
        <p>Your use or viewing of the Social Media Accounts, such as social networking pages, blogs, and file sharing sites (i.e., Facebook, Twitter, LinkedIn, Instagram), is subject to this Policy, the Terms of Service, and the terms of use of the applicable social media platform.  All comments submitted or posted on the Social Media Accounts are subject to public disclosure. To the extent we collect any personal information about you through the Social Media Accounts, we handle such personal information in accordance with this Policy. For additional terms, conditions, requirements, and restrictions related to your use of, access to, or view of any of the Social Media Accounts, please refer to the Terms of Service.</p>
        <h2>DISCLAIMER, LIABILITY, AND RELEASE</h2>
        <h3>Disclaimer</h3>
        <p>THE REPRESENTATIONS AND WARRANTIES SET FORTH IN THIS POLICY, IF ANY, ARE THE BATTERS BOX'S ONLY REPRESENTATIONS AND WARRANTIES AND NO OTHER REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, WILL APPLY.  TO THE FULLEST EXTENT PERMITTED BY LAW, NEITHER THE BATTERS BOX NOR ANY PERSON ASSOCIATED WITH THE BATTERS BOX MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE BATTERS BOX NOR ANYONE ASSOCIATED WITH THE BATTERS BOX REPRESENTS OR WARRANTS THAT THE SERVICES OR THE CONTENTS THEREIN WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT ANY OF THE SERVICES OR THE SERVER OR PLATFORM THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.  THE BATTERS BOX EXPRESSLY DISCLAIMS ANY RESPONSIBILITY FOR ANY ACTS OR OMISSIONS OF OTHER USERS OF THE SERVICES. IF YOU ARE DISSATISFIED WITH THE SERVICES, YOUR SOLE REMEDY IS TO DISCONTINUE USING THE SERVICES. Some jurisdictions do not allow the disclaimer of warranties, so such disclaimer may not apply to you.  The limitation of liability set forth above shall only apply to the extent permitted by applicable law.</p>
        <h3>Limitation of Liability</h3>
        <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL THE BATTERS BOX BE LIABLE FOR ANY SPECIAL, INDIRECT, PUNITIVE, COVER, INCIDENTAL OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, WHETHER IN CONTRACT OR TORT OR UNDER ANY OTHER THEORY OF LIABILITY, INCLUDING WITHOUT LIMITATION, LOSS OF REVENUE, ANTICIPATED PROFITS, BUSINESS OR SALES, ANY LOSS OF GOODWILL OR REPUTATION, OR THE COSTS OF SUBSTITUTE GOODS OR SERVICES, EVEN IF THE BATTERS BOX OR AN AUTHORIZED REPRESENTATIVE THEREOF HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.   NOTWITHSTANDING ANYTHING CONTAINED HEREIN TO THE CONTRARY, IN NO EVENT SHALL THE TOTAL LIABILITY OF THE BATTERS BOX TO YOU OR ANY THIRD PARTY FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, PRODUCT LIABILITY OR OTHERWISE) ARISING FROM THIS POLICY OR YOUR ACCESS TO OR USE OF THE SERVICES EXCEED, IN THE AGGREGATE, THE AMOUNT PAID BY YOU TO THE BATTERS BOX FOR ITS SERVICES, WHICH FOR PURPOSES OF CLARITY, IF YOU HAVE NOT PAID ANY AMOUNT, THE BATTERS BOX SHALL NOT BE LIABLE FOR ANY AMOUNT. Some jurisdictions do not allow the exclusion of damages, so such exclusions may not apply to you. The limitation of liability set forth above shall only apply to the maximum extent permitted by applicable law.</p>
        <h3>Limitation on Time to File Claims</h3>
        <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THIS POLICY OR YOUR USE OF THE SERVICES MUST BE COMMENCED WITHIN SIX (6) MONTHS AFTER THE CAUSE OF ACTION ACCRUES, OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
        <h3>Release</h3>
        <p>If you have a dispute with one (1) or more other users of any of the Services, you release us (and our affiliates and subsidiaries, and our and their respective officers, directors, employees and agents) from claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes. IN ENTERING INTO THIS POLICY YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THE RELEASE CONTAINED HEREIN TO INCLUDE ONLY THOSE CLAIMS WHICH YOU MAY KNOW OR SUSPECT TO EXIST IN YOUR FAVOR AT THE TIME OF AGREEING TO THIS POLICY, INCLUDING, WITHOUT LIMITATION, CALIFORNIA CIVIL CODE §1542, WHICH SAYS: "A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR."</p>
        <h2>MISCELLANEOUS</h2>
        <h3>Governing Law</h3>
        <p>By accessing any of the Services, you agree that this Policy and your use of the Services shall be governed exclusively by the laws of the State of Illinois without regard to conflict of laws principles, regardless of your location when accessing the Services.  This Policy is entered into and performed in Chicago (Cook County), Illinois USA.  This Policy does not give rise to personal jurisdiction over The Batters Box, either specific or general, in jurisdictions other than Illinois. The Batters Box makes no representation that the Services are appropriate or will be available for use in other locations. If you use a site from outside the United States of America, you are entirely responsible for compliance with applicable local laws, including but not limited to the export and import regulations of other countries in relation to the Services and third party content.</p>
        <h3>Dispute Resolution</h3>
        <p>The parties shall attempt to resolve any disputes through good faith business negotiations or facilitative mediation in Chicago, Illinois. Except as otherwise determined by The Batters Box, in its sole discretion, all disputes or claims arising out of or relating to this Policy shall be settled by arbitration, to be conducted by a single arbitrator in Chicago, Illinois by and in accordance with the then effective commercial rules of the American Arbitration Association; provided, however, that the arbitrator shall not have authority to issue injunctions. The proceedings shall be conducted only in the English language. Judgment upon the award may be entered in any court having jurisdiction thereof. Other legal proceedings, if any, shall be initiated and maintained in Cook County, Illinois. The parties expressly submit to the exclusive personal jurisdiction and venue of these courts and waive any objection on the grounds of personal jurisdiction, venue, or forum non conveniens.</p>
        <h3>Attorneys' Fees and Costs</h3>
        <p>If any litigation or other court action, arbitration, or similar adjudicatory proceeding is commenced by any party to enforce its rights under this Policy against the other party, all fees, costs and expenses, including, without limitation, cost of arbitration, reasonable attorneys' fees and court costs, incurred by The Batters Box, if it is the prevailing party in such litigation, action, arbitration or proceeding, shall be reimbursed by you; provided, however, that if The Batters Box prevails in part, and loses in part, the court, arbitrator or other adjudicator presiding over such litigation, action, arbitration or proceeding shall award a reimbursement of the fees, costs and expenses incurred by The Batters Box on an equitable basis.</p>
        <h3>Amendment of this Policy</h3>
        <p>The Batters Box may, modify, add or remove any portion of this Policy from time to time, in its sole discretion with or without notice to you, by posting such changes on the Site or App. Once posted such changes shall come into full force and effect. In addition to other forms of acceptance, use of the Services constitutes acceptance of this Policy, including continued use after such changes are posted. It is your responsibility to check periodically for changes to this Policy.</p>
        <h3>Notices</h3>
        <p>Any notice to The Batters Box must be in writing and must be sent via email to contactbattersbox@gmail.com. Any notice to you shall be given by email to the email address provided by you during registration as a premium user, as subsequently updated by you, or as otherwise provided by you to us, any of which you agree shall be sufficient notice to you. Notice shall be deemed to have been given to you upon 24 hours after it has been sent or at the time the information was posted on or through any of the Services. If you change your email address, you are responsible for updating your account profile or notifying us.</p>
        <h3>Severability</h3>
        <p>If any provision of this Policy is held by an arbitrator or court of competent jurisdiction to be invalid or unenforceable, then such provision shall be enforced to the maximum extent permissible so as to affect the intent of this Policy, and the remainder of this Policy shall continue in full force and effect. </p>
        <h3>No Waiver</h3>
        <p>The failure by The Batters Box to exercise or enforce any rights or provisions of this Policy shall not constitute a waiver of such right or provision. </p>
        <h3>No Partnership</h3>
        <p>No partnership, joint venture, franchisor-franchisee, or agency relationship is intended or created by this Policy. </p>
        <h3>Assignment</h3>
        <p>You may not assign or transfer this Policy or your obligations hereunder in whole or in part, whether by operation of law or otherwise, without The Batters Box's prior written consent. In the event of a permitted transfer, this Policy shall bind and inure to the benefit of the parties, their respective successors and permitted assigns. To the extent any novation is required for The Batters Box to assign this Policy, you hereby appoint the officers of The Batters Box as your attorney-in-fact to execute all documents necessary to consummate such novation.  The Batters Box may assign or transfer this Policy or its obligations hereunder in whole or in part, whether by operation of law or otherwise, without obtaining your consent.</p>
        <h3>Survival</h3>
        <p>All provisions in this Policy regarding representations and warranties, content, indemnification, disclaimers and limitations on liability shall survive any termination of this Policy.</p>
        <h3>Force Majeure</h3>
        <p>Any delay in the performance of any duties or obligations of The Batters Box will not be considered a breach of this Policy if such delay is caused by a labor dispute, market shortage of materials, fire, earthquake, flood or any other event beyond the control of The Batters Box, provided that The Batters Box uses reasonable efforts, under the circumstances, to resume performance as soon as reasonably practicable.</p>
        <h3>Language</h3>
        <p>If a translation of this Policy into any other language is required by law, the English version will prevail to the extent that there is any conflict or discrepancy in meaning between the English version and any translation thereof. If this Policy is provided to you in a language other than English, The Batters Box does so solely for your convenience.</p>
        <h3>Entire Agreement</h3>
        <p>This Policy, the Terms of Service, and any applicable Other Agreements contain the entire agreement between you and The Batters Box, and supersede all previous communications, representations, understandings and agreements, either oral or written, between us.</p>
        <h2>CONTACT INFORMATION</h2>
        <p>If you have any questions or concerns about this Policy or about The Batters Box's privacy practices, please send an email message to contactbattersbox@gmail.com.</p>
        <p>If you are a California resident, in accordance with Cal. Civ. Code §1789.3, you may report complaints to the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by contacting them in writing at 400 R Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.</p>
        <em>If you are an EU resident and believe you have suffered harm due to a breach of your rights by The Batters Box under this Policy, and The Batters Box has not handled your complaint in a reasonably sufficient manner, you may also file a complaint with the applicable supervisory authority. In addition, you may contact our Data Protection Officer at contactbattersbox@gmail.com or by sending your privacy-related comments or questions to the address set forth above.</em>
    </div>
  )
}

export default PrivacyPolicy;
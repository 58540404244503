import React, {useState, useContext} from "react";
import {useNavigate} from "react-router-dom"
import { UserContext } from '../Context/UserContext';
import Logos from "./Logos";

function Signup({firstName, setFirstName, lastName, setLastName, email, setEmail, password, setPassword, passwordConfirmation, setPasswordConfirmation}) {
    const {state} = useContext(UserContext);
    const [errorsState, setErrorsState] = useState([])
    const navigate = useNavigate()
    const {signup} = useContext(UserContext)

    function handleSubmit(event) {
        event.preventDefault()
        fetch('/signup', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                email: email,
                password: password,
                password_confirmation: passwordConfirmation
            })
        })
        .then(res => res.json())
        .then(user => {
            if (!user.errors) {
                signup(user)

                const subscription = user.subscription
                //console.log('sub', subscription)
                const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
                //console.log('hasSub?', hasSub)
                const today = new Date()
                const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
                //console.log('active sub?', isActiveSub)

                if (isActiveSub) {
                    navigate('/ratings/hitters')
                }
                else {
                    navigate('/checkout')
                }
            }
            else {
                setFirstName("")
                setLastName("")
                setEmail("")
                setPassword("")
                setPasswordConfirmation("")
                const errors = user.errors.map(e => <li className="errors">{e}</li>)
                setErrorsState(errors)
            }
        })
    }

    function navigateToLogin() {
        navigate('/login')
        window.scrollTo(0, 0)
    }

    if (errorsState.length > 0)
    return (
        <div>
            <Logos />
            <div className="signupContainer">
                <h2>Create an Account</h2>
                <form onSubmit={handleSubmit}>
                <label>First Name</label>
                    <br />
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <br />
                    <label>Last Name</label>
                    <br />
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <br />
                    <label>Email Address</label>
                    <br />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <label>Password</label>
                    <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <label>Confirm Password</label>
                    <br />
                    <input type="paswword" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <br />
                    <button type="submit">Submit</button>
                </form>
                {errorsState}
                <h3>Already Have an Account?</h3>
                <button onClick={navigateToLogin}>Login</button>
            </div>
        </div>
    )
    else
    return (
        <div>
            <Logos />
            <div className="signupContainer">
                <h2>Create an Account</h2>
                <form onSubmit={handleSubmit}>
                <label>First Name</label>
                    <br />
                    <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    <br />
                    <label>Last Name</label>
                    <br />
                    <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    <br />
                    <label>Email Address</label>
                    <br />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <label>Password</label>
                    <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <p style={{fontSize: "0.8rem", marginTop: "-0.05rem"}}>password must contain at least 8 characters</p>
                    <label>Confirm Password</label>
                    <br />
                    <input type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <br />
                    <button type="submit">Submit</button>
                </form>
                <h3>Already Have an Account?</h3>
                <button onClick={navigateToLogin}>Login</button>
            </div>
        </div>
    )
}

export default Signup
import React from "react";

function ColorLabels () {

    return (
        <div className="centerColors">
            <div className="colorLabels">
                <li id="color-key">color key: </li>
                <li id="elite">elite</li>
                <li id="great">great</li>
                <li id="above-average">above average</li>
                <li id="poor">poor</li>
                <li id="sortableColor">sortable column</li>
            </div>
        </div>
    )
}

export default ColorLabels
import React from "react"
import {useNavigate} from "react-router-dom"

function SignupLoginButtons() {
    const navigate = useNavigate()

    function navigateToSignUp() {
        navigate('/signup')
    }

    function navigateToLogin() {
        navigate('/login')
    }

    return (
        <div className="signUpLoginButtons">
            <button onClick={navigateToSignUp}>Sign Up</button>
            <button onClick={navigateToLogin}>Login</button>
        </div>
    )
}

export default SignupLoginButtons
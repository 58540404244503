import React, {useState} from "react";

function Glossary() {
    const [showGlossary, setShowGlossary] = useState(false)

    function handleGlossary() {
        setShowGlossary(!showGlossary)
    }

    const glossaryTerms = [
        {
            term: "Matchup Rating:",
            definition: "My way of determining how favorable of a matchup it is for the hitter. The higher the score, the better the matchup. Read more on the help page."
        },
        {
            term: "Plate Appearances (PA):",
            definition: "The number of times a batter has taken a turn at the plate. Each completed turn batting is one plate appearance."
        },
        {
            term: "Weighted On-Base Average (wOBA):",
            definition: "A version of on-base percentage that accounts for how a player reached base, instead of simply considering whether or not the player did. The value for each method of reaching base is determined by how much that event is worth in relation to projected runs scored. For example, a double is worth more than a single."
        },
        {
            term: "Matchup wOBA:",
            definition: "My calculation involving both the hitter's wOBA and the wOBA allowed by the pitcher to hitters of that same handedness as the hitter."
        },
        {
            term: "Isolated Power (ISO):",
            definition: "Measures the raw power of a hitter by taking only extra-base hits into account."
        },
        {
            term: "Matchup ISO:",
            definition: "My calculation involving both the hitter's ISO and the ISO allowed by the pitcher to hitters of that same handedness as the hitter."
        },
        {
            term: "Hard Contact Rate:",
            definition: "The percentage of batted balls that were hit at 95 mph or more."
        },
        {
            term: "Matchup Hard Contact Rate:",
            definition: "My calculation involving both the hitter's hard contact rate and the hard contact rate allowed by the pitcher to hitters of that same handedness as the hitter."
        },
        {
            term: "Weight Runs Created Plus (wRC+):",
            definition: "The runs created statistic adjusted for ballpark or era. 100 is average. Runs created was invented by Bill James and measures how well a hitter creates runs by estimating a player's offensive contribution in terms of total runs."
        },
        {
            term: "HR/FB:",
            definition: "The rate at which home runs are hit for every fly ball hit."
        },
        {
            term: "Matchup HR/FB:",
            definition: "My calculation involving both the hitter's HR/FB and the HR/FB allowed by the pitcher to hitters of that same handedness as the hitter."
        },
        {
            term: "Fly Ball Rate (FB Rate):",
            definition: "The percentage of balls hit into the field of play that are characterized as fly balls."
        },
        {
            term: "Matchup FB Rate:",
            definition: "My calculation involving both the hitter's FB rate and the FB rate allowed by the pitcher to hitters of that same handedness as the hitter."
        },
        {
            term: "Groundball Rate (GB Rate):",
            definition: "The percentage of balls hit into the field of play that are characterized as ground balls."
        },
        {
            term: "Strikeout Rate (K Rate):",
            definition: "The percentage of plate appearances that end in strike outs."
        },
        {
            term: "Innings Pitched (SP IP):",
            definition: "The number of innings the starting pitcher has pitched against hitters of the handedness of that hitter over the past 3 seasons (including this season)."
        },
        {
            term: "Soft Contact Rate:",
            definition: "The opposite of hard contact rate, the higher the number, the better for the pitcher."
        },
        {
            term: "Walk Rate:",
            definition: "The percentage of hitters a pitcher walks, as determined by total walks divided by total batters faced."
        },
        {
            term: "Expected Fielding Independent Pitching (xFIP):",
            definition: "xFIP uses a pitcher's FIP (Fielding Independent Pitching), but it substitues projected home-run rate instead of actual home runs allowed. FIP is similar to ERA, but it focuses solely on the events a pitcher has the most control over (strikeouts, unintentional walks, hit-by-pitches and home runs). It entirely removes results on balls hit into the field of play. xFIP is predicated on the notion that pitchers have more control over how many fly balls they allow than how many of those fly balls leave the park."
        }
    ]


    if (showGlossary)
    return (
        <div className="glossary">
            <button id="glossaryBtn" onClick={handleGlossary}>{showGlossary ? "Hide Glossary" : "See Glossary"}</button>
            {glossaryTerms.map(gt => {
                return <div key={gt.term} className="glossaryList">
                    <ul>
                        <li className="glossaryLi" id="glossaryTerm">{gt.term} </li>
                        <li className="glossaryLi">{gt.definition}</li>
                    </ul> 
                </div>
            })}
            <p id="glossaryCourtesy">courtesy: MLB and Fangraphs</p>  
        </div>
    )
    else return (
        <div className="glossary">
            <button id="glossaryBtn" onClick={handleGlossary}>{showGlossary ? "Hide Glossary" : "See Glossary"}</button>
        </div>
    )
}

export default Glossary
import React from "react";
import {useNavigate} from "react-router-dom"
import TableImage from "../images/septemer 2023 random top 10 snip.jpg"

function About() {
    const navigate = useNavigate()
    function navigateToBreakdown() {
        navigate('/guide')
        window.scrollTo(0, 0)
    }

    return (
        <div className="about">
            <h1>About The Batter's Box</h1>
            <p>Welcome to The Batter's Box, where baseball enthusiasts and bettors alike step up to the plate for a grand slam experience!</p>
            <p>We're your ultimate resource hub for Major League Baseball content and tools for betting and daily fantasy lineups. Whether you're a seasoned bettor in search of data-driven advice or a fantasy fanatic craving advanced insights, we've got everything you need to knock it out of the park.</p>
            <p>From power hitters to pitching aces, our curated selection of tools, picks, and data empowers you to make informed decisions that turn every game into a chance to win. With our expert analysis and insider knowledge, you'll be rounding the bases with confidence and precision.</p>
            <p>But hold on, there's an extra inning to this game! The Batter's Box isn't solely about betting and fantasy. We're revving up to bring you a full roster of baseball content that'll knock your socks off. Delve into our articles or catch us on videos and live streams for a closer look at our tools, insights, and America's beloved pastime.</p>
            <p>So whether you're a die-hard fan, a casual observer, or a betting aficionado, step into The Batter's Box and join us in celebrating the sport we love. Get ready to swing for the fences and hit it out of the park with us!</p>
            <p>Want to read more on the ratings and how to use them? Check out our breakdown below.</p>
            <div className="defaultArticlesContainer">
                <div className="newBreakdownContainer" onClick={navigateToBreakdown}>
                    <h2>Batters Box Breakdown</h2>
                    <img id="tableImage" src={TableImage} alt="hitter table"></img>
                    <p>An In-Depth Guide and How to Use the Ratings</p>
                </div>
            </div>
        </div>
    )
}

export default About
import React, {useState, useContext} from "react";
import { UserContext } from '../Context/UserContext';

function UserSettings() {
    const {state, dispatch} = useContext(UserContext);
    const [seeCurrentEmail, setSeeCurrentEmail] = useState(false)
    const [changeEmail, setChangeEmail] = useState(false)
    const [newEmail, setNewEmail] = useState('')
    const [confirmNewEmail, setConfirmNewEmail] = useState('')
    const [errors, setErrors] = useState('')
    const [message, setMessage] = useState('')

    function toggleCurrentEmail() {
        setSeeCurrentEmail(!seeCurrentEmail)
    }

    function toggleChangeEmail() {
        setChangeEmail(!changeEmail)
    }

    function handleChange(e) {
        const {name, value} = e.target
        if (name === "newEmail") setNewEmail(value)
        if (name === "confirmNewEmail") setConfirmNewEmail(value)
    }

    function handleSubmit(e) {
        e.preventDefault()
        if (newEmail !== confirmNewEmail) {
            setErrors("Emails do not match")
        }
        else {
            fetch(`/users/${state.user.id}/update_email`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    user_id: state.user.id,
                    new_email: newEmail
                })
            })
            .then(res => res.json())
            .then(data => {
                if (!data.errors) {
                    setNewEmail('')
                    setConfirmNewEmail('')
                    setErrors('')
                    setMessage("Email updated successfully")
                    dispatch({type: 'setUser', payload: data})
                }
                else {
                    setErrors(data.error)
                }
            }
        )}
    }

    return (
        <div className="userSettings">
            <h2>Welcome, {state.user?.first_name}</h2>
            <button onClick={toggleCurrentEmail}>{seeCurrentEmail ? "Hide Current Email" : "See Current Email"}</button>
            <br />
            {seeCurrentEmail && <p>Current Email: {state.user?.email}</p>}
            <br />
            <button onClick={toggleChangeEmail}>{changeEmail ? "Cancel Email Change" : "Change Email?"}</button>
            <br />
            {changeEmail && (
                <div className="changeEmailForm">
                    <p>Make sure you have access to your new email. It is how you will login and how we will communicate with you.</p>
                    <form onSubmit={handleSubmit}>
                        <label>New Email: </label>
                        <br />
                        <input type="email" id="newEmail" name="newEmail" value={newEmail} onChange={handleChange} required></input>
                        <br />
                        <label>Confirm New Email: </label>
                        <br />
                        <input type="email" id="confirmNewEmail" name="confirmNewEmail" value={confirmNewEmail} onChange={handleChange} required></input>
                        <br />
                        <button type="submit">Save Changes</button>
                    </form>
                    {message && <p style={{color: "blue"}}>{message}</p>}
                    {errors && <p className="errors">{errors}</p>}
                </div>
            )}
        </div>
    )
}

export default UserSettings
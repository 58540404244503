import React, {useState} from "react";
import Logos from "./Logos";

function GetPasswordResetLink() {
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)

    function handleEmailChange(e) {
        setEmail(e.target.value)
    }

    function handleEmailSubmit(e) {
        e.preventDefault()

        fetch('/api_password_resets', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                email: email
            })
        })
        .then(res => res.json())
        .then(data => {
            //console.log('data from get password reset link', data)
            if (!data.error) {
                setEmailSent(true)
            }
            else (
                alert(data.error)
            )
        })
    }

    if (emailSent) return (
        <div>
            <Logos />
            <div className="getPWResetLinkContainer">
                <h2>Forgot Password?</h2>
                <form onSubmit={handleEmailSubmit}>
                    <label>Email: </label>
                    <input id="email" type="text" value={email} onChange={handleEmailChange} placeholder="Enter email address" />
                    <button type="submit">Send Email</button>
                </form>
                <h2>Password Instructions Sent to Your Email.</h2>
            </div>
        </div>
    )
    else return (
        <div>
            <Logos />
            <div className="getPWResetLinkContainer">
                <h2>Forgot Password?</h2>
                <form onSubmit={handleEmailSubmit}>
                    <label>Email: </label>
                    <input id="email" type="text" value={email} onChange={handleEmailChange} placeholder="Enter email address" />
                    <button type="submit">Send Email</button>
                </form>
            </div>
        </div>
    )
}

export default GetPasswordResetLink
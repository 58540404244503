import React from "react";
import {useNavigate} from "react-router-dom"
import GetPremium from "./GetPremium";
import TableImage from "../images/septemer 2023 random top 10 snip.jpg"
import HighlightImage from "../images/highlights.png"
import OhtaniGraph from "../images/ohtani graph.png"
import OhtaniEliteRatings from "../images/Ohtani last elite ratings.png"

function NewFor2024() {
    const navigate = useNavigate()

    function navigateToBreakdown() {
        navigate('/guide')
        window.scrollTo(0, 0)
    }
    function navigateToHighlights() {
        navigate('/highlights/2023')
        window.scrollTo(0, 0)
    }

    return (
        <div className="newFor2024Container">
            <br />
            <GetPremium className={"getPremiumContainer"}/>
            <h2>What's New for 2024?</h2>
            <p>You want more? We got WAY more! We've been hard at work all offseason to add even more value. All of the new features will be for premium users only so if you're on the fence about the subscription, this is where you want to be.</p>
            <h3>Current Season Hitter Ratings</h3>
            <p>The default hitter ratings are based on data from the past three seasons. This is good for getting a larger sample size but could be misleading for a hitter that has struggled more recently, like Javier Baez. Ratings for just the current season will have much smaller sample sizes so they will react more quickly to new data. For example, a hitter going 4-for-4 or 0-for-4 will have a much bigger impact on current season ratings than the default three-year timeframe. If you want a more reactive rating, the current season one is for you. These will be available once hitters have 20 plate appearances in 2024 and once pitchers have 10 innings pitched against the handedness of the hitter.</p>
            <h3>Hitter Trends</h3>
            <p>This is by far my favorite new feature! Premium users can search a hitter and see several things. First, you can see their last 10 games when they had an elite rating and their box score for each game. This will show you how well the hitter has been doing lately with an elite rating and if today's rating is higher or lower than his most recent elite ratings.</p>
            <img id="last10EliteImage" src={OhtaniEliteRatings} alt="TBD img"></img>
            <p>Secondly, you'll see how successful they have been in a number of categories for all of their elite ratings. As you can see below the GOAT, Shohei Ohtani was absolutely outstanding with elite ratings in 2023. This will allow you to determine if there's some implied value in any wagers based on the current odds or if you're making daily fantasy lineups you can use this as another way to compare guys, especially if there is someone with high success rates at a low price.</p>
            <img id="eliteGraphImage" src={OhtaniGraph} alt="TBD img"></img>
            <h3>Pitcher Ratings</h3>
            <p>Ratings for starting pitchers (or someone else if the team deploys an opener) are brand new and never before used. As I get more data, I will be tracking them as well so stay tuned to see how they do. These will be available for BOTH the default-three year time frame and the current season.</p>
            <p>Each pitcher's matchup rating is based on the top nine opposing hitter's matchup ratings. This is calculated before lineups are released so this could include guys that are not in the lineup. However, you'll also be able to see the average hitter matchup rating, the average hitter matchup strikeout rate, the average hitter matchup groundball rate, the number of opposing hitters with an elite rating, the number of opposing hitters with a poor rating and more. I think this will be useful for attempting to project a pitchers strikeouts and might be even more useful for determining who might win the game. Stay tuned as I get more pitcher ratings and the ability to track how well they're doing.</p>
            {/* <h3>Favorites</h3>
            <p>Who are the hitters or pitchers that people are liking each day? Premium users have the ability to favorite hitters and pitchers and also see how many times the hitter/pitcher has been added to other users' favorites. Then the next day you'll be able to see your favorite hitters/pitchers and how well they performed. Maybe you like someone but not enough to put them in your DFS lineup or wager on them. Favorite those players and check the next day to see if you're choosing the right people. I think this will quietly be one of the better features available.</p> */}
            <p>Author: Mike Montgomery <a href="https://twitter.com/mikemonty22" target="_blank" rel='noreferrer'>
                    <img className="twitterLogo" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/twitter-512.png" alt=""/>
            </a></p>
            <div className="defaultArticlesContainer">
                <div className="newBreakdownContainer" onClick={navigateToBreakdown}>
                    <h2>Batters Box Breakdown</h2>
                    <img id="tableImage" src={TableImage} alt="hitter table"></img>
                    <p>An In-Depth Guide and How to Use the Ratings</p>
                </div>
                <div className="homePreviewsArticle" onClick={navigateToHighlights}>
                    <h2>2023 Highlights</h2>
                    <img id="highlightImage" src={HighlightImage} alt="2023 Highlights"></img>
                    <p>Recapping the top players and best moments from our first season</p>
                </div>
            </div>
        </div>
    )
}

export default NewFor2024
import React, {useContext, useState} from "react";
import { UserContext } from '../Context/UserContext';
import LogoutButton from "./LogoutButton";
import {useNavigate} from "react-router-dom"

function UserIcon() {
    const {state} = useContext(UserContext);
    const navigate = useNavigate()
    const [clicked, setClicked] = useState(false)
    const userInitial = state.user?.first_name ? state.user?.first_name[0] : ""

    function handleClick() {
        setClicked(!clicked)
    }

    // function navigateToUserFavorites() {
    //     navigate('/user/favorites')
    // }

    function navigateToUserSettings() {
        navigate('/user/settings')
    }

    if (userInitial !== "")
    return (
        <div className={`userIconContainer ${clicked ? 'open' : ''}`} onClick={handleClick}>
            <p id="userInitial">{userInitial}</p>
            {clicked && userInitial !== "" && (
                <div className="iconDropDownContainer">
                    <LogoutButton />
                    {/* <p onClick={navigateToUserFavorites}>Favorites</p> */}
                    <p onClick={navigateToUserSettings}>Settings</p>
                </div>
            )}
        </div>
    )
    else return (<></>)
}

export default UserIcon
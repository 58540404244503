import React from "react";
import {useNavigate} from "react-router-dom"
import NewFeaturesImage from "../images/2024.png"
import HighlightImage from "../images/highlights.png"
import GetPremium from "./GetPremium";

function BattersBoxBreakdown() {
    const navigate = useNavigate()

    function navigateToNewFeatures() {
        navigate('/new-features')
        window.scrollTo(0, 0)
    }

    function navigateToHighlights() {
        navigate('/highlights/2023')
        window.scrollTo(0, 0)
    }

    return (
        <div className="breakdownContainer">
            <br />
            <GetPremium className={"getPremiumContainer"}/>
            <h2>Batters Box Breakdown</h2>
            <h3>An In-Depth Guide and How to Use It</h3>
            <p>Welcome to The Batters Box, the newest home for baseball content. We're focused on leveraging our unique matchup ratings to provide premier betting content, but we will expand beyond sports betting, so keep your eyes out for videos and podcasts to come.</p>
            <h3>What is the matchup rating?</h3>
            <p>The Matchup rating is a score generated by our algorithm for each hitter against a specific pitcher, usually the starter (though exceptions may apply if a team uses an opener). It is not a projection, but instead, attempts to determine how favorable the spot is. The higher the score, the better or more favorable the matchup is. It is a unique calculation based on a set of advanced baseball statistics, with each given a certain weight. It favors hitters who make more hard contact since harder contact typically leads to more positive results (it's difficult to hit a home run without hitting the ball hard).</p>
            <h3>Matchup Rating History</h3>
            <p>I created the rating system several years ago to play daily fantasy (DFS) baseball. It quickly became my favorite sport for DFS. My initial goal was to make enough to "feed the habit" without needing to deposit more money into the account. This rating system allowed me to do just that. I played mostly tournaments, hoping to take down some contests. Occasionally I would finish in the top 10 or top 5 and take home what I considered a big payday but never won thousands of dollars. The 2023 season was the first time the ratings were publicized after I learned how to code and put the website together. Without having as much time to dedicate to DFS lineup building, I focused on using the ratings for betting. It was a successful first season and I'll share my favorite ways to use the table below.</p>
            <h3>How to use the table?</h3>
            <p>A few notes before I dig into my favorite ways to use the table for betting. First, I'm almost exclusively betting plus-money wagers, meaning I want +100 odds or better. I am planning on tracking more bets that are heavily juiced before I bet on them. Premium users will have access to hitter trends that will show how successful the hitter has been when the hitter has an elite rating. For example, let's pretend Cody Bellinger has an elite rating and he is getting a hit 70% of the time with an elite rating. If his odds to get a hit are -200 (that implies a 66.67% of getting a hit) then technically there would be value in betting him to get a hit at -200. I'm going to try and track this before wagering on it, but that is one way a premium user could use the rating system.</p>
            <p>Second, I prefer to target hitters who have a plus matchup in the key matchup stats (wOBA, ISO and Hard Contact). For example: if Fernando Tatis Jr. has a .380 wOBA vs left-handed pitching and his matchup wOBA for today is .400, this would be a plus matchup for that statistic. I don't exclusively target hitters with a plus matchup in the three key stats, nor do I have data to support this (I should as the 2024 season gets underway) but, in my experiences, these matchups tend to work well.</p>
            <p>Third, I like to think of the ratings as the best place to start: use the table to determine which hitters are in the most favorable spots and do further research from there. Extra research I do includes how well the hitter does against the pitcher's specific pitches and how well the hitter has been performing lately. Feel free to take what I have done and put your own spin on it.</p>
            <p>Fourth, scroll to the right on the hitters table and look at the stats the opposing pitcher is allowing. How good has that pitcher done? Is he giving up a lot of hard contact or a high ISO? Are his numbers better or worse than a lot of other pitchers? These are all questions I try to answer as I'm getting started for the day. Come here to learn how to fish in your own way.</p>
            <p className="paragraphHeader">1. Hit Parlays</p>
            <p>This started out as my favorite way to bet. Depending on the individual odds you can bet two hitters in some of the most favorable matchups to each get a hit at plus money. Sometimes you might need a three-leg hit parlay but you'll likely be getting better odds. I would also have fun making five-, six-, or seven-leg hit parlays for fun but those are much riskier.</p>
            <p  className="paragraphHeader">2. Over 1.5 Hits, Runs and RBI</p>
            <p>With the ratings originating from a daily fantasy perspective, it's no surprise that elite rated hitters are more likely to go over 1.5 hits, runs and RBI than over 1.5 total bases. This is not a prop I bet much last season, but since tracking the data, I am more interested and confident in betting this prop for the 2024 season. From June 23, 2023, to the end of the 2023 season, the hitter with the highest rated matchup rating went over 1.5 hits runs and RBI over 58% of the time, which would be equivalent to about -140 odds. Thus, we have a bet with implied value on the highest rated hitter if he has odds to go over 1.5 hits, runs and RBI better than -140 (-120, -110, +100 etc.). I am almost certain this will happen multiple times throughout the season so keep an eye on this. If you become a premium user, you will be able to see this information for any hitter.</p>
            <p  className="paragraphHeader">3. Over 1.5 Total Bases + Double/HR Sprinkle</p>
            <p>This is a high risk, high reward strategy I developed during the 2023 season. I only like this strategy if the hitter's odds to go over 1.5 total bases are +100 or better. In this case, I would bet one unit (whatever your standard bet is) on total bases and bet half of a unit on the batter to hit a double and half of a unit on the batter to hit a home run. Thus, betting two units in total on one hitter. Why? Doubles and home runs always have great odds, typically well over +200 depending on the player. Those are also the two most common outcomes for extra base hits. Let's look at an example: for the first game of the Seoul Series this season you could bet Fernando Tatis Jr. over 1.5 total bases at +145. His odds to hit a HR were +450 and to hit a double were +425. If he would have hit two singles or a triple, you win the total bases wager gaining 1.45 units, but losing both the double and home run wagers losing 1 unit but overall profiting 0.45 units. If he hits a double or home run you would win 1.45 units for the bases and over 2 units for the double or home run. All scenarios considered here, you could win up to almost six units if the player hits a home run and a double or lose up to two units. A high risk, high reward strategy that I will look to use often.</p>
            <h3>Conclusion</h3>
            <p>The ratings tables provide a lot of key information that can be used however you see fit. It's more of an art than a science. What jumps out to me, may not jump out to you or someone else. The more you use it, the more comfortable you will become. Come back often to create your own system!</p>
            <p>Author: Mike Montgomery <a href="https://twitter.com/mikemonty22" target="_blank" rel='noreferrer'>
                    <img className="twitterLogo" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/twitter-512.png" alt=""/>
            </a></p>
            <div className="defaultArticlesContainer">
                <div className="homePreviewsArticle" onClick={navigateToNewFeatures}>
                    <h2>What's New?</h2>
                    <img id="newFeaturesImage" src={NewFeaturesImage} alt="New 2024 Features"></img>
                    <p>Taking a look at the plethora of new features for 2024</p>
                </div>
                <div className="homePreviewsArticle" onClick={navigateToHighlights}>
                    <h2>2023 Highlights</h2>
                    <img id="highlightImage" src={HighlightImage} alt="2023 Highlights"></img>
                    <p>Recapping the top players and best moments from our first season</p>
                </div>
            </div>
        </div>
    )
}

export default BattersBoxBreakdown
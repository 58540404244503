import React from "react";

function FeatureComparison() {

    return (
        <div className="featureComparisonContainer">
            <div className="premiumTier">
                <h2>Premium Features</h2>
                <p id="everythingInFree">Free Default Ratings Plus:</p>
                <ul>
                    <li>Current Season Hitter Ratings</li>
                    <li>Percent Change in Matchup Stats on Hitter Ratings Table</li>
                    <li>Player Stats & Trends</li>
                    <li className="example">Example: Cody Bellinger Has Recorded a Hit 70% of the Time When He Has an Elite Rating</li>
                    <li>Pitcher Ratings*</li>
                    <li className="example">Current Season & 3 Year Time Frame</li>
                    <li className="example">*Brand New Ratings Being Tested</li>
                    {/* <li>Favorite Hitter and Pitchers</li> */}
                </ul>
            </div>
        </div>
    )
}

export default FeatureComparison
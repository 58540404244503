import React, {useContext, useState, useEffect, useMemo} from "react";
import { UserContext } from '../Context/UserContext';
import ColorLabels from "./ColorLabels";
import PitcherGlossary from "./PitcherGlossary"

function ThreeYearPitcherRatings() {
    const {state, dispatch} = useContext(UserContext);
    const userID = state.user.id
    const [filteredThreeYearStats, setFilteredThreeYearStats] = useState(state?.dailyPitcherThreeYearStats)
    const [errorsState, setErrorsState] = useState([])
    const [favoritePitchers, setFavoritePitchers] = useState([])
    const [sortDirection, setSortDirection] = useState(true)
    const [sortState, setSortState] = useState("Matchup Rating")

    //console.log('sortState', sortState)
    //console.log('stats', state.dailyPitcherThreeYearStats)

    const isUser = state.loggedIn ? true : false
    //const subscription = state.user.subscription
    //console.log('sub', subscription)
    //const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    //console.log('hasSub?', hasSub)
    //const today = new Date()
    //const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    useEffect(() => {
        if (filteredThreeYearStats.length === 0) {
            setFilteredThreeYearStats(state.dailyPitcherThreeYearStats)
        }
    }, [state.dailyPitcherThreeYearStats, filteredThreeYearStats])

    useEffect(() => {
        //if (isActiveSub)
        if (isUser)
        fetch('/favorite_pitchers')
            .then(res => res.json())
            .then(data => setFavoritePitchers(data))
    }, [isUser])

    function handleSort(e) {
        const category = e.target.textContent.toLowerCase().replace(/ /g, "_");
        if (e.target.textContent === "Matchup Rating") {
            setSortState("pitcher_matchup_rating_thirty_percent")
        }
        else {
            setSortState(category)
        }
        setSortDirection((prevDirection) => !prevDirection)
    }

    function handleChange(e) {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined)
        setFilteredThreeYearStats(state.dailyPitcherThreeYearStats)
    else
        setFilteredThreeYearStats(state.dailyPitcherThreeYearStats?.filter(stat => stat.pitcher_name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    const sortedPitchers = useMemo(() => {
        const sortedStats = [...filteredThreeYearStats].sort((a, b) => {
            const aValue = a[sortState];
            const bValue = b[sortState];
            const comparison = aValue - bValue;
            return sortDirection ? comparison : -comparison;
        });
        return sortedStats;
    }, [filteredThreeYearStats, sortState, sortDirection])

    // function handleFavoriteCount(pitcherID) {
    //     if (favoritePitchers.some(item => item.pitcher_id === pitcherID && item.user_id === userID && item.timeframe === "three year")) {
    //         fetch(`/daily_pitcher_three_year_stats/${pitcherID}/decrement_favorite_count`, {
    //             method: "PATCH",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 pitcher_id: pitcherID,
    //                 user_id: state.user.id,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (!data.errors) {
    //                 setFavoritePitchers(favoritePitchers.filter(fav => fav.user_id === userID && fav.pitcher_id !== pitcherID))
    //                 const mapPitcher3y = filteredThreeYearStats.map((stat) => {
    //                     if (stat.pitcher_id === pitcherID) {
    //                         return {...stat, favorite_count: data.favorite_count}
    //                     }
    //                     else return stat
    //                 })
    //                 setFilteredThreeYearStats(mapPitcher3y)
    //                 dispatch({type: "fetchDailyPitcherThreeYearStats", payload: mapPitcher3y})
    //             }
    //             else {
    //                 const errors = data.errors.map(e => <li>{e}</li>)
    //                 setErrorsState(errors)
    //             }
    //         })
    //     }
    //     else {
    //         fetch(`/daily_pitcher_three_year_stats/${pitcherID}/increment_favorite_count`, {
    //             method: "PATCH",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 pitcher_id: pitcherID,
    //                 user_id: userID,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (!data.errors) {
    //                 setFavoritePitchers([...favoritePitchers, {user_id: userID, pitcher_id: pitcherID}])
    //                 const mapPitcher3y = filteredThreeYearStats.map((stat) => {
    //                     if (stat.pitcher_id === pitcherID) {
    //                         return {...stat, favorite_count: data.favorite_count}
    //                     }
    //                     else return stat
    //                 })
    //                 setFilteredThreeYearStats(mapPitcher3y)
    //                 dispatch({type: "fetchDailyPitcherThreeYearStats", payload: mapPitcher3y})
    //             }
    //             else {
    //                 const errors = data.errors.map(e => <li>{e}</li>)
    //                 setErrorsState(errors)
    //             }
    //         })
    //     }
    // }

    if (filteredThreeYearStats.length === 0) {
        return (
            <div className="spinner">
            </div>
        )
    }
    else if (isUser) {
        return (
            <div className="ratings-container">
                <div className="searchBar">
                    <label>Search A Pitcher: </label>
                    <input type="text" placeholder="Ex: Justin Steele" onChange={(e) => handleChange(e)}></input>
                </div>
                <ColorLabels />
                <PitcherGlossary />
                <div className="pitcher-table-container">
                    <table className="pitcher-ratings-table">
                        <thead>
                            <tr>
                                {/* <th>Favorite</th> */}
                                <th>Name</th>
                                <th>Team</th>
                                <th>Throw Hand</th>
                                <th>Opponent</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Matchup Rating</th>
                                <th>IP</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup Rating</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup wOBA</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup ISO</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup Hard Contact</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup K Pct</th>
                                <th className="sortable" onClick={(e) => handleSort(e)}>Avg Hitter Matchup GB Pct</th>
                                <th>Number of Elite Rated Hitters</th>
                                <th>Number of Poor Rated Hitters</th>
                                <th>Number Hitters Above MLB Avg K Pct</th>
                                <th>Number Hitters Above MLB Avg GB Pct</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedPitchers?.map((stat) => {
                                const teamStyles = {
                                    backgroundColor: 
                                        (stat.pitcher_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                        (stat.pitcher_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                        (stat.pitcher_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                        (stat.pitcher_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                        (stat.pitcher_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                        (stat.pitcher_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                        (stat.pitcher_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                        (stat.pitcher_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                        (stat.pitcher_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                        (stat.pitcher_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                        (stat.pitcher_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                        (stat.pitcher_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                        (stat.pitcher_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                        (stat.pitcher_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                        (stat.pitcher_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                        (stat.pitcher_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                        (stat.pitcher_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                        (stat.pitcher_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                        (stat.pitcher_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                        (stat.pitcher_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                        (stat.pitcher_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                        (stat.pitcher_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                        (stat.pitcher_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                        (stat.pitcher_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                        (stat.pitcher_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                        (stat.pitcher_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                        (stat.pitcher_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                        (stat.pitcher_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                                };
                                const strikeoutRateStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_k_pct >= 30.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_k_pct >= 26.0 && stat.avg_hitter_matchup_k_pct < 30.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_k_pct > 23.0 && stat.avg_hitter_matchup_k_pct < 26.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_k_pct <= 20.0 ? 'lightpink' : '')
                                }
                                const groundballRateStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_gb_pct >= 50.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_gb_pct >= 46.0 && stat.avg_hitter_matchup_gb_pct < 50.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_gb_pct > 43.0 && stat.avg_hitter_matchup_gb_pct < 46.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_gb_pct <= 37.0 ? 'lightpink' : '')
                                }
                                const hardContactStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_hard_contact <= 26.0 ? 'lightblue' : '') || (stat.avg_hitter_matchup_hard_contact >= 26.01 && stat.avg_hitter_matchup_hard_contact < 29.0 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_hard_contact >= 29.01 && stat.avg_hitter_matchup_hard_contact < 32.0 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_hard_contact >= 36.5 ? 'lightpink' : '')
                                }
                                const isoStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_iso <= 0.129 ? 'lightblue' : '') || (stat.avg_hitter_matchup_iso >= 0.130 && stat.avg_hitter_matchup_iso < 0.140 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_iso >= 0.140 && stat.avg_hitter_matchup_iso < 0.150 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_iso >= 0.175 ? 'lightpink' : '')
                                }
                                const wobaStyles = {
                                    backgroundColor: (stat.avg_hitter_matchup_woba <= 0.280 ? 'lightblue' : '') || (stat.avg_hitter_matchup_woba > 0.280 && stat.avg_hitter_matchup_woba < 0.300 ? 'lightgreen' : '') || (stat.avg_hitter_matchup_woba >= 0.300 && stat.avg_hitter_matchup_woba < 0.330 ? 'lightyellow' : '') || (stat.avg_hitter_matchup_woba >= 0.350 ? 'lightpink' : '')
                                }
                                const matchupRatingStyles = {
                                    backgroundColor: (stat.pitcher_matchup_rating_thirty_percent <= 5.63 ? 'lightblue' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.63 && stat.pitcher_matchup_rating_thirty_percent <= 5.85 ? 'lightgreen' : '') || (stat.pitcher_matchup_rating_thirty_percent > 5.85 && stat.pitcher_matchup_rating_thirty_percent < 6.05 ? 'lightyellow' : '') || (stat.pitcher_matchup_rating_thirty_percent >= 6.4 ? 'lightpink' : '')
                                }
                                return (
                                    <tr key={stat.id}>
                                        {/* <td className={favoritePitchers.some(item => item.pitcher_id === stat.pitcher_id && item.user_id === userID && item.timeframe === "three year") ? "favorite" : ""}>{stat?.favorite_count} {<button className="favButton" onClick={() => handleFavoriteCount(stat.pitcher_id)}>⭐</button>}</td> */}
                                        <td>{stat?.pitcher_name}</td>
                                        <td style={teamStyles}>{stat?.pitcher_team}</td>
                                        <td>{stat?.throw_hand}</td>
                                        <td>{stat?.hitter_team}</td>
                                        <td style={matchupRatingStyles}>{stat?.pitcher_matchup_rating_thirty_percent}</td>
                                        <td>{stat?.pitcher_ip}</td>
                                        <td>{stat?.avg_hitter_matchup_rating}</td>
                                        <td style={wobaStyles}>{stat?.avg_hitter_matchup_woba}</td>
                                        <td style={isoStyles}>{stat?.avg_hitter_matchup_iso}</td>
                                        <td style={hardContactStyles}>{stat?.avg_hitter_matchup_hard_contact}%</td>
                                        <td style={strikeoutRateStyles}>{stat?.avg_hitter_matchup_k_pct}%</td>
                                        <td style={groundballRateStyles}>{stat?.avg_hitter_matchup_gb_pct}%</td>
                                        <td>{stat?.num_elite_rated_hitters}</td>
                                        <td>{stat?.num_poor_rated_hitters}</td>
                                        <td>{stat?.num_hitters_above_avg_k_pct}</td>
                                        <td>{stat?.num_hitters_above_avg_gb_pct}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default ThreeYearPitcherRatings
export const initialState = {
    initialLoad: true,
    paymentStatus: false,
    user: {},
    loggedIn: false,
    games: [],
    authors: [],
    articles: [],
    dailyCurrentSeasonStats: [],
    dailyThreeYearStats: [],
    dailyPitcherCurrentSeasonStats: [],
    dailyPitcherThreeYearStats: []
}

export function reducer(state, action) {
    switch (action.type) {
        case 'setPaymentStatus':
            return {
                ...state,
                paymentStatus: action.payload
            }
        case 'setUser':
            return {
                ...state,
                user: action.payload
            }
        case 'setLoad':
            return {
                ...state,
                initialLoad: false    
            }
        case 'login':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'signup':
            return {
                ...state,
                user: action.payload,
                loggedIn: true
            }
        case 'logout':
            return {
                ...state,
                user: {},
                loggedIn: false
            }
        case 'setLoggedIn':
            return {
                ...state,
                loggedIn: action.payload
            }
        case 'fetchGames':
            return {
                ...state,
                games: action.payload
            }
        case 'fetchAuthors':
            return {
                ...state,
                authors: action.payload
            }
        case 'fetchArticles':
            return {
                ...state,
                articles: action.payload
            }
        case 'fetchDailyCurrentSeasonStats':
            return {
                ...state,
                dailyCurrentSeasonStats: action.payload
            }
        case 'fetchDailyThreeYearStats':
            return {
                ...state,
                dailyThreeYearStats: action.payload
            }
        case 'fetchDailyPitcherCurrentSeasonStats':
            return {
                ...state,
                dailyPitcherCurrentSeasonStats: action.payload
            }
        case 'fetchDailyPitcherThreeYearStats':
            return {
                ...state,
                dailyPitcherThreeYearStats: action.payload
            }
        default:
    }
}
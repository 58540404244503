import React from "react";
import logoImage from "../logos/the-batters-box-icon.svg"
import logoWords from "../logos/the-batters-box-wordmark-horizontal.svg"

function Logos() {
    return (
        <div className="logoContainer">
            <img id="iconLogo" src={logoImage} alt="Logo"></img>
            <img id="logoWords" src={logoWords} alt="Logo2"></img>
        </div>
    )
}

export default Logos
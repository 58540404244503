import React from "react"
import FeatureComparison from "./FeatureComparison"

function SubPassComp() {

    return (
        <div>
            <div>
                <div className="subscriptionTypeContainer">
                    <div className="dayPassContainer">
                        <h2 id="dayPassHeader">Day Pass</h2>
                        <h3>Access for the Day</h3>
                        <h3>$3</h3>
                        <p>Only buy on days with MLB games</p>
                        <p>NOT a 24 hour pass.</p>
                        <p>Does NOT renew</p>
                    </div>
                    <FeatureComparison />
                    {/* <div className="monthPassContainer">
                        <h2>Monthly Pass</h2>
                        <h3>Access for the month</h3>
                        <h3>$20/month</h3>
                        <p>Automatically renews each month</p>
                    </div> */}
                    <div className="seasonPassContainer">
                        <h2>Season Pass</h2>
                        <h3>Access for the 2024 Regular Season and Postseason</h3>
                        <h3>$10 - $100</h3>
                        <p>Depending on when you purchase</p>
                        <p>Does NOT renew</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubPassComp
import React from "react";

function CurrentSeasonEliteStrikeoutPitcherResults({pitcherData}) {

    if (pitcherData?.elite_rating_percentages_current_season_strikeouts[0]?.number_elite_strikeout_rate_ratings < 10) {
        return (
            <div className="hitterResultsContainer">
                <h2>{pitcherData?.last_10_elite_results_current_season_strikeouts[0]?.name}'s Last {pitcherData?.elite_rating_percentages_current_season_strikeouts[0]?.number_elite_strikeout_rate_ratings} Games with Elite Avg Hitter Strikeout Pct for the Current Season</h2>
                <table className="eliteResultsTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Avg Hitter Strikeout Pct</th>
                            <th>IP</th>
                            <th>H</th>
                            <th>R</th>
                            <th>ER</th>
                            <th>HR</th>
                            <th>BB</th>
                            <th>K</th>
                            <th>Win</th>
                            <th>Loss</th>
                            {/* <th>FD Pts</th>
                            <th>DK Pts</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {pitcherData?.last_10_elite_results_current_season_strikeouts?.map((stat) => {
                            return (
                                <tr key={stat.date}>
                                    <td>{stat.date}</td>
                                    <td>{stat.rounded_matchup_rating}%</td>
                                    <td>{stat.innings_pitched}</td>
                                    <td>{stat.hits}</td>
                                    <td>{stat.runs}</td>
                                    <td>{stat.earned_runs}</td>
                                    <td>{stat.home_runs}</td>
                                    <td>{stat.walks}</td>
                                    <td>{stat.strikeouts}</td>
                                    <td>{stat.win}</td>
                                    <td>{stat.loss}</td>
                                    {/* <td>{stat.fanduel_points}</td>
                                    <td>{stat.draftkings_points}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    else
    return (
        <div className="hitterResultsContainer">
            <h2>{pitcherData?.last_10_elite_results_current_season_strikeouts[0]?.name}'s Last 10 Games with Elite Avg Hitter Strikeout Pct for the Current Season</h2>
            <table>
                <thead className="eliteResultsTable">
                    <tr>
                        <th>Date</th>
                        <th>Avg Hitter Strikeout Pct</th>
                        <th>IP</th>
                        <th>H</th>
                        <th>R</th>
                        <th>ER</th>
                        <th>HR</th>
                        <th>BB</th>
                        <th>K</th>
                        <th>Win</th>
                        <th>Loss</th>
                        {/* <th>FD Pts</th>
                        <th>DK Pts</th> */}
                    </tr>
                </thead>
                <tbody>
                    {pitcherData?.last_10_elite_results_current_season_strikeouts?.map((stat) => {
                        return (
                            <tr key={stat.date}>
                                <td>{stat.date}</td>
                                <td>{stat.rounded_matchup_rating}%</td>
                                <td>{stat.innings_pitched}</td>
                                <td>{stat.hits}</td>
                                <td>{stat.runs}</td>
                                <td>{stat.earned_runs}</td>
                                <td>{stat.home_runs}</td>
                                <td>{stat.walks}</td>
                                <td>{stat.strikeouts}</td>
                                <td>{stat.win}</td>
                                <td>{stat.loss}</td>
                                {/* <td>{stat.fanduel_points}</td>
                                <td>{stat.draftkings_points}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default CurrentSeasonEliteStrikeoutPitcherResults
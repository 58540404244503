import React, {useState} from "react";

function PitcherGlossary() {
    const [showGlossary, setShowGlossary] = useState(false)

    function handleGlossary() {
        setShowGlossary(!showGlossary)
    }

    const glossaryTerms = [
        {
            term: "General Overview:",
            definition: "All of these stats (except innings pitched) are calculated from the opponent's top 9 hitters based on matchup rating. Therefore, some highly rated hitters may not make the lineup. You can check who the top 9 are by going to the individual game. It's the top 9 since those theoretically would make the 'best' lineup for that team."
        },
        {
            term: "Matchup Rating:",
            definition: "My way of determining how favorable of a matchup it is for the pitcher. The lower the score, the better the matchup."
        },
        {
            term: "Innings Pitched (IP):",
            definition: "The number of innings the starting pitcher has pitched over the past 3 seasons (including this season)."
        },
        {
            term: "Avg Hitter Matchup Rating:",
            definition: "The average matchup rating of the opponent's top 9 hitters based on matchup rating. Lower is better for the pitcher, meaning a more difficult matchup for the hitters."
        },
        {
            term: "Avg Hitter Matchup wOBA (Weighted On-Base Average)",
            definition: "The average matchup wOBA of the opponent's top 9 hitters based on matchup rating. Lower is better for the pitcher. wOBA is a version of on-base percentage that accounts for how a player reached base, instead of simply considering whether or not the player did. The value for each method of reaching base is determined by how much that event is worth in relation to projected runs scored. For example, a double is worth more than a single. "
        },
        {
            term: "Avg Hitter Matchup ISO (Isolated Power)",
            definition: "The average matchup ISO of the opponent's top 9 hitters based on matchup rating. Lower is better for the pitcher. ISO measures the raw power of a hitter by taking only extra-base hits into account"
        },
        {
            term: "Avg Hitter Matchup Hard Contact",
            definition: "The average matchup hard contact of the opponent's top 9 hitters based on matchup rating. Lower is better for the pitcher."
        },
        {
            term: "Avg Hitter Matchup K Pct",
            definition: "The average matchup strikeout rate of the opponent's top 9 hitters based on matchup rating. Higher is better for the pitcher."
        },
        {
            term: "Avg Hitter Matchup GB (Ground Ball) Pct",
            definition: "The average matchup ground ball rate of the opponent's top 9 hitters based on matchup rating. Higher is better for the pitcher. Ground balls are more likely to turn into outs so a high ground ball rate is good for pitchers."
        },
        {
            term: "Number of Elite Rated Hitters",
            definition: "The number of elite rated hitters of the opponent's top 9 hitters based on matchup rating. 9 is the max."
        },
        {
            term: "Number of Poor Rated Hitters",
            definition: "The number of poor rated hitters of the opponent's top 9 hitters based on matchup rating. 9 is the max."
        },
        {
            term: "Number of Hitters Above MLB Avg K (Strikeout) Pct",
            definition: "The number of hitters who have a matchup strikeout rate greater than the league average strikeout rate (typically around 22%). 9 is the max."
        },
        {
            term: "Number of Hitters Above MLB Avg GB (Ground Ball) Pct",
            definition: "The number of hitters who have a matchup ground ball rate greater than the league average ground ball rate (typically around 42%). 9 is the max."
        }
    ]


    if (showGlossary)
    return (
        <div className="glossary">
            <button id="glossaryBtn" onClick={handleGlossary}>{showGlossary ? "Hide Pitcher Glossary" : "See Pitcher Glossary"}</button>
            {glossaryTerms.map(gt => {
                return <div key={gt.term} className="glossaryList">
                    <ul>
                        <li className="glossaryLi" id="glossaryTerm">{gt.term} </li>
                        <li className="glossaryLi">{gt.definition}</li>
                    </ul> 
                </div>
            })}
            <p id="glossaryCourtesy">courtesy: MLB and Fangraphs</p>  
        </div>
    )
    else return (
        <div className="glossary">
            <button id="glossaryBtn" onClick={handleGlossary}>{showGlossary ? "Hide Pitcher Glossary" : "See Pitcher Glossary"}</button>
        </div>
    )
}

export default PitcherGlossary
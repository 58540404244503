import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom"
import Cookies from 'js-cookie'

function AnnouncementPopUp({onAcknowledge}) {
    const [isVisible, setIsVisible] = useState(false)
    const navigate = useNavigate()
    //console.log('announcment pop up log')

    function navigateToTerms() {
        navigate('/terms-of-service')
        window.scrollTo(0, 0)
    }

    function navigateToPrivacy() {
        navigate('/privacy-policy')
        window.scrollTo(0, 0)
    }

    useEffect(() => {
        const termsAcknowledged = Cookies.get('termsAcknowledged')
        if (!termsAcknowledged) {
            setIsVisible(true)
        }
    }, [])

    function handleAcknowledge() {
        Cookies.set('termsAcknowledged', 'true', {expires: 365, sameSite: 'Lax'})
        setIsVisible(false)
        onAcknowledge();
    }

    if (!isVisible) {
        return null
    }

    return (
        <div className="announcementPopUp">
            <div className="popUpContent">
                <h3>We've updated our terms of service and privacy policy.</h3>
                <p>With these changes, users will better understand the scope of our services and how we collect data.</p>
                <p id="boldP">Please note that by using the website and services you consent to the updated terms. If you do not agree with these changes, you should stop accessing the website and/or delete your account by making a request to the email below.</p>
                <p>The updated terms and privacy policy can be found here:</p>
                <ul>
                    <li style={{textDecoration: "underline", cursor: "pointer"}} onClick={navigateToTerms}>Terms of Service</li>
                    <li style={{textDecoration: "underline", cursor: "pointer"}} onClick={navigateToPrivacy}>Privacy Policy</li>
                </ul>
                <p>Questions? Email us at: contactbattersbox@gmail.com</p>
                <p>Click the button below to close the announcment.</p>
                <button onClick={handleAcknowledge}>Acknowledge</button>
            </div>
        </div>
    )
}

export default AnnouncementPopUp
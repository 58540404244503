import React, {useContext, useState, useMemo, useEffect} from "react";
import { UserContext } from '../Context/UserContext';
import ColorLabels from "./ColorLabels";
import Glossary from "./Glossary";

function CurrentSeasonHitterRatings() {
    const {state, dispatch} = useContext(UserContext);
    const userID = state.user.id
    const [filteredCurrentSeasonStats, setFilteredCurrentSeasonStats] = useState([...state?.dailyCurrentSeasonStats])
    const [errorsState, setErrorsState] = useState([])
    const [favoriteHitters, setFavoriteHitters] = useState([])
    const [sortDirection, setSortDirection] = useState(false)
    const [sortState, setSortState] = useState("")
    //console.log('sortState', sortState)

    const isUser = state.loggedIn ? true : false
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    // //console.log('active sub?', isActiveSub)
    // const favoriteColumn = isActiveSub && <th>Favorite</th>;

    // useEffect(() => {
    //     if (isActiveSub)
    //     fetch('/favorite_hitters')
    //         .then(res => res.json())
    //         .then(data => {
    //             //console.log('data', data)
    //             setFavoriteHitters(data)
    //         }
    // )}, [isActiveSub])

    function handleSort(e) {
        const category = e.target.textContent.toLowerCase().replace(" ", "_");
        if (e.target.textContent === "Matchup FB Rate") {
            setSortState("hit_matchup_fb_pct")
        }
        else if (e.target.textContent === "Matchup Hard Contact") {
            setSortState("hit_matchup_hard_contact")
        }
        else {
            const stateCategory = `hit_${category}`
            setSortState(stateCategory)
        }
        setSortDirection((prevDirection) => !prevDirection)
    }

    const sortedHitters = useMemo(() => {
        const sortedStats = [...filteredCurrentSeasonStats].sort((a, b) => {
            const aValue = a[sortState];
            const bValue = b[sortState];
            const comparison = aValue - bValue;
            return sortDirection ? comparison : -comparison;
        });
        return sortedStats;
    }, [filteredCurrentSeasonStats, sortState, sortDirection])

    function handleChange(e) {
        if (e.target.value === "" || e.target.value === null || e.target.value === undefined)
        setFilteredCurrentSeasonStats(state.dailyCurrentSeasonStats)
    else
        setFilteredCurrentSeasonStats(state.dailyCurrentSeasonStats.filter(stat => stat.hitter_name.toLowerCase().includes(e.target.value.toLowerCase())))
    }

    // function updateFavoriteCount(hitterID) {
    //     if (favoriteHitters.some(item => item.hitter_id === hitterID && item.user_id === userID && item.timeframe === "current season")) {
    //         fetch(`/daily_current_season_stats/${hitterID}/decrement_favorite_count`, {
    //             method: "PATCH",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 hitter_id: hitterID,
    //                 user_id: userID,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (!data.errors) {
    //                 setFavoriteHitters(favoriteHitters.filter(fav => fav.user_id === userID && fav.hitter_id !== hitterID))
    //                 const mapCS = filteredCurrentSeasonStats.map((stat) => {
    //                     if (stat.hitter_id === hitterID) {
    //                         return {...stat, hitter_favorite_count: data.hitter_favorite_count}
    //                     }
    //                     else return stat
    //                 })
    //                 setFilteredCurrentSeasonStats(mapCS)
    //                 dispatch({type: "fetchDailyCurrentSeasonStats", payload: mapCS})
    //             }
    //             else {
    //                 const errors = data.errors.map(e => <li>{e}</li>)
    //                 setErrorsState(errors)
    //             }
    //         })
    //     }
    //     else {
    //         fetch(`/daily_current_season_stats/${hitterID}/increment_favorite_count`, {
    //             method: "PATCH",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify({
    //                 hitter_id: hitterID,
    //                 user_id: userID,
    //             })
    //         })
    //         .then(res => res.json())
    //         .then(data => {
    //             if (!data.errors) {
    //                 setFavoriteHitters([...favoriteHitters, {user_id: userID, hitter_id: hitterID}])
    //                 const mapCS = filteredCurrentSeasonStats.map((stat) => {
    //                     if (stat.hitter_id === hitterID) {
    //                         return {...stat, hitter_favorite_count: data.hitter_favorite_count}
    //                     }
    //                     else return stat
    //                 })
    //                 setFilteredCurrentSeasonStats(mapCS)
    //                 dispatch({type: "fetchDailyCurrentSeasonStats", payload: mapCS})
    //             }
    //             else {
    //                 const errors = data.errors.map(e => <li>{e}</li>)
    //                 setErrorsState(errors)
    //             }
    //         })
    //     }
    // }

    return (
        <div className="ratings-container">
            <div className="searchBar">
                <label>Search A Hitter: </label>
                <input type="text" placeholder="Ex: Kris Bryant" onChange={(e) => handleChange(e)}></input>
            </div>
            <ColorLabels />
            <Glossary />
            <div className="table-container">
                <table className="current-ratings-table">
                    <thead>
                        <tr>
                            {/* {favoriteColumn} */}
                            <th>Name</th>
                            <th>Team</th>
                            <th>Bat Hand</th>
                            <th className="sortable" onClick={(e) => handleSort(e)}>Matchup Rating</th>
                            <th>PA</th>
                            <th>WRC+</th>
                            <th>wOBA</th>
                            <th className="sortable" onClick={handleSort}>Matchup wOBA</th>
                            <th>wOBA Pct Change</th>
                            <th>ISO</th>
                            <th className="sortable" onClick={handleSort}>Matchup ISO</th>
                            <th>ISO Pct Change</th>
                            <th>Hard Contact</th>
                            <th className="sortable" onClick={handleSort}>Matchup Hard Contact</th>
                            <th>Hard Contact Pct Change</th>
                            <th>HR/FB</th>
                            <th>Matchup HR/FB</th>
                            <th>HR/FB Pct Change</th>
                            <th>FB Pct</th>
                            <th className="sortable" onClick={handleSort}>Matchup FB Rate</th>
                            <th>FB Pct Change</th>
                            <th>SP Name</th>
                            <th>SP Team</th>
                            <th>SP Throw Hand</th>
                            <th>SP IP</th>
                            <th>SP wOBA</th>
                            <th>SP ISO</th>
                            <th>SP Hard Contact Rate</th>
                            <th>SP Soft Contact Rate</th>
                            <th>SP K Rate</th>
                            <th>SP Walk Rate</th>
                            <th>SP xFIP</th>                        
                            <th>SP FB Rate</th>
                            <th>SP GB Rate</th>
                            <th>SP HR/FB</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedHitters?.map((stat) => {
                            const teamStyles = {
                                backgroundColor: 
                                    (stat.hitter_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                    (stat.hitter_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                    (stat.hitter_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                    (stat.hitter_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                    (stat.hitter_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                    (stat.hitter_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                    (stat.hitter_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                    (stat.hitter_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                    (stat.hitter_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                    (stat.hitter_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                    (stat.hitter_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                    (stat.hitter_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                    (stat.hitter_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                    (stat.hitter_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                    (stat.hitter_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                    (stat.hitter_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                    (stat.hitter_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                    (stat.hitter_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                    (stat.hitter_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                    (stat.hitter_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                    (stat.hitter_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                    (stat.hitter_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                    (stat.hitter_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                                };
                            const matchupRatingStyles = {
                                backgroundColor: (stat.hit_matchup_rating >= 0.450 ? 'lightblue' : '') || (stat.hit_matchup_rating >= 0.400 && stat.hit_matchup_rating < 0.450 ? 'lightgreen' : '') || (stat.hit_matchup_rating > 0.333 && stat.hit_matchup_rating < 0.400 ? 'lightyellow' : '') || (stat.hit_matchup_rating <= 0.230 ? 'lightpink' : '')
                            }
                            const matchupWOBAStyles = {
                                backgroundColor: (stat.hit_matchup_woba >= 0.400 ? 'lightblue' : '') || (stat.hit_matchup_woba < 0.400 && stat.hit_matchup_woba > 0.350 ? 'lightgreen' : '') || (stat.hit_matchup_woba >= 0.315 && stat.hit_matchup_woba < 0.350 ? 'lightyellow' : '') || (stat.hit_matchup_woba <= 0.300 ? 'lightpink' : '')
                            }
                            const matchupISOStyles = {
                                backgroundColor: (stat.hit_matchup_iso >= 0.275 ? 'lightblue' : '') || (stat.hit_matchup_iso < 0.275 && stat.hit_matchup_iso >= 0.200 ? 'lightgreen' : '') || (stat.hit_matchup_iso > 0.160 && stat.hit_matchup_iso < 0.199 ? 'lightyellow' : '') || (stat.hit_matchup_iso <= 0.150 ? 'lightpink' : '')
                            }
                            const matchupHCStyles = {
                                backgroundColor: (stat.hit_matchup_hard_contact >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_hard_contact < 50.0 && stat.hit_matchup_hard_contact >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_hard_contact >= 33.0 && stat.hit_matchup_hard_contact < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_hard_contact <= 29.0 ? 'lightpink' : '')
                            }
                            const matchupFBStyles = {
                                backgroundColor: (stat.hit_matchup_fb_pct >= 50.0 ? 'lightblue' : '') || (stat.hit_matchup_fb_pct < 50.0 && stat.hit_matchup_fb_pct >= 40.0 ? 'lightgreen' : '') || (stat.hit_matchup_fb_pct > 37.0 && stat.hit_matchup_fb_pct < 40.0 ? 'lightyellow' : '') || (stat.hit_matchup_fb_pct <= 30.0 ? 'lightpink' : '')
                            }
                            const wOBApercentChangeStyles = {
                                color: (stat.hit_woba_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_woba_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const ISOpercentChangeStyles = {
                                color: (stat.hit_iso_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_iso_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const HCpercentChangeStyles = {
                                color: (stat.hit_hard_contact_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_hard_contact_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const HRFBpercentChangeStyles = {
                                color: (stat.hit_hr_per_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_hr_per_fb_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            const FBpercentChangeStyles = {
                                color: (stat.hit_fb_percent_change > 0 ? 'rgba(0, 100, 0)' : "" ) || (stat.hit_fb_percent_change <= 0 ? 'rgba(139, 0, 0)' : '')
                            }
                            return (
                                <tr key={stat.id}>
                                    {/* {favoriteColumn && (
                                        <td className={favoriteHitters.some(item => item.hitter_id === stat.hitter_id && item.user_id === userID && item.timeframe === "current season") ? "favorite" : ""}>{stat?.hitter_favorite_count} {<button className="favButton" onClick={() => updateFavoriteCount(stat.hitter_id)}>⭐</button>}</td>
                                    )} */}
                                    <td>{stat?.hitter_name}</td>
                                    <td style={teamStyles}>{stat?.hitter_team}</td>
                                    <td>{stat?.bat_hand}</td>
                                    <td style={matchupRatingStyles}>{stat?.hit_matchup_rating?.toFixed(3)}</td>
                                    <td>{stat?.hit_pa}</td>
                                    <td>{stat?.hit_wrc_plus}</td>
                                    <td>{stat?.hit_woba}</td>
                                    <td style={matchupWOBAStyles}>{stat?.hit_matchup_woba?.toFixed(3)}</td>
                                    <td style={wOBApercentChangeStyles}>{stat?.hit_woba_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_iso}</td>
                                    <td style={matchupISOStyles}>{stat?.hit_matchup_iso?.toFixed(3)}</td>
                                    <td style={ISOpercentChangeStyles}>{stat?.hit_iso_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hard_contact_pct}%</td>
                                    <td style={matchupHCStyles}>{stat?.hit_matchup_hard_contact?.toFixed(1)}%</td>
                                    <td style={HCpercentChangeStyles}>{stat?.hit_hard_contact_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_hr_per_fb}%</td>
                                    <td>{stat?.hit_matchup_hr_per_fb?.toFixed(1)}%</td>
                                    <td style={HRFBpercentChangeStyles}>{stat?.hit_hr_per_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.hit_fb_pct}%</td>
                                    <td style={matchupFBStyles}>{stat?.hit_matchup_fb_pct?.toFixed(1)}%</td>
                                    <td style={FBpercentChangeStyles}>{stat?.hit_fb_percent_change?.toFixed(1)}%</td>
                                    <td>{stat?.pitcher_name}</td>
                                    <td>{stat?.pitcher_team}</td>
                                    <td>{stat?.throw_hand}</td>
                                    <td>{stat?.sp_ip}</td>
                                    <td>{stat?.sp_woba}</td>
                                    <td>{stat?.sp_iso}</td>
                                    <td>{stat?.sp_hard_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_soft_contact.toFixed(1)}%</td>
                                    <td>{stat?.sp_kpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_bbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_xfip}</td>
                                    <td>{stat?.sp_fbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_gbpct.toFixed(1)}%</td>
                                    <td>{stat?.sp_hr_per_fb.toFixed(1)}%</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default CurrentSeasonHitterRatings
import React from "react";

function FAQs() {
    
    const FAQs = [
        {
            question: "How do you use the table to make bets?",
            answer: "I use the table to begin my research and help show me which hitters I want to start with. I do not make any bets based entirely off the table but it's a great resource to separate the good from the bad. If you like to bet home runs then you want to pay attention to matchup hard contact rate and flyball rate. For total bases bets, I like to pay attention to all of the categories that have colors. Unfortunately, the table does not simply mean bet the guy at the top."
        },
        {
            question: "How do I deal with openers as starting pitchers?",
            answer: "Openers have become increasingly popular from MLB teams the last few years. I will try to list the reliever that is expected to pitch the bulk of the innings and calculate matchup ratings based on that pitcher. However, sometimes those are based on reports and are inaccurate. For instance, it was reported that Michael Grove would come in after the opener for the Dodgers and pitch the bulk of innings against the Angels on June 21st, 2023. He ended up not pitching at all. Not sure why but sometimes that happens so I do my best but keep this in mind. There are plenty of resources to double check who the starting pitcher is and who I have calculated ratings against."
        },
        {
            question: "Why are one or more games missing?",
            answer: "If one or more games are missing it is likely because one or both of the pitchers in the game have not pitched at least ten innings against right handed hitters or left handed hitters, or they have a double header. Unfortunately we do not offer any ratings for both games when double headers occur. We will have ratings for one of the games during double headers but not both."
        },
        {
            question: "Why is a hitter missing?",
            answer: "There are two reasons a hitter may be missing. One, he may not have 20 plate appearances yet against the handedness of the starting pitcher that day. I do not caluclate matchup ratings for a hitter with less than 20 plate appearances. It is all based on the handedness of the pitcher as well. So if the hitter has 20 plate appearances against right handed pitchers but less than that versus left handed pitchers, he will be on the table versus a right handed pitcher but not versus a left handed pitcher. Second, if he is not on the teams active roster he will not be on the table. Sometimes those transactions happen closer to game time, after I have updated the stats and calculations so he may be missing for that particular game."
        },
        {
            question: "What is matchup rating?",
            answer: "Matchup rating is the score my algorithm gives each hitter for the matchup vs the starting pitcher. It is not a projection. It is calculated from a set of other statistics with each statistic given a certain weight. Think of it as a way to determine how good of a matchup it is for the hitter. The higher the score, the better the matchup."
        },
        {
            question: "What are the other matchup categories",
            answer: "Each matchup category is the calculation made for that particular statistic based off the hitter and starting pitcher. For example, matchup iso is the iso for this particular instance of the hitter and starting pitcher based on each of their iso's."
        },
        {
            question: "Is the data updated every day?",
            answer: "Yes, the data is updated each morning."
        },
        {
            question: "What period is the data from?",
            answer: "The default data includes all plate appearances for the past 3 seasons where the appearance was against the pitcher of the same handedness they are facing today. For example: if the hitter is facing a left handed pitcher today, then all of his columns are data vs left handed pitchers from the past three seasons. If you're a premium subscriber, you can use current season ratings which is just data for the current active season."
        },
        {
            question: "Why so many years worth of data?",
            answer: "The larger the sample size the more accurate the averages will be. That is why we show the amount of plate appearances (PA) on the table. If a hitter has fewer PA then the data is likely to be skewed in one direction. Keep that in mind when looking at the data."
        },
        {
            question: "What does the alogrithm include?",
            answer: "It simply factors a handful of hitters and pitchers stats, each in regards to the handedness of the hitter/pitcher. I don't want to give out the exact formula but it does not take the following into consideration: weather, lineups, injuries, ballpark, bullpens, umpires, pitch-type, and more."
        },
        {
            question: "How does this work with double headers?",
            answer: "Currently there is no support for double headers. If if there are any double headers, only one of the two games for that team will be on the site."
        },
        {
            question: "Will there always be picks?",
            answer: "There hopefully will be picks each day. "
        },
        {
            question: "How can I contact you?",
            answer: "Email contactbattersbox@gmail.com."
        }
    ]

    return (
        <div className="FAQs">
            <h1 id="FAQH1">Frequently Asked Questions</h1>
            {FAQs.map(q => {
                return <div key={q.question}>
                    <h2 className="FAQH2">{q.question}</h2>
                    <p>⚾ {q.answer}</p>
                </div>
            })}
        </div>
    )
}

export default FAQs
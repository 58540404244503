import React, {useState, useContext} from "react";
import {useNavigate} from "react-router-dom"
import { UserContext } from '../Context/UserContext';
import Logos from "./Logos";

function Login({email, setEmail, password, setPassword}) {
    const {state} = useContext(UserContext);
    const [errorsState, setErrorsState] = useState([])
    const navigate = useNavigate()
    const {login} = useContext(UserContext)

    function handleSubmit(event) {
        event.preventDefault()
        fetch('/login', {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password
            })
        })
        .then(res => res.json())
        .then(user => {
            if (!user.errors) {
                login(user)

                // const subscription = user.subscription
                // //console.log('sub', subscription)
                // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
                // //console.log('hasSub?', hasSub)
                // const today = new Date()
                // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
                //console.log('active sub?', isActiveSub)

                navigate('/ratings/hitters')
                // if (isActiveSub) {
                //     navigate('/ratings/hitters')
                // }
                // else {
                //     navigate('/checkout')
                // }
            }
            else {
                setEmail("")
                setPassword("")
                const errors = user.errors.map(e => <li className="errors">{e}</li>)
                setErrorsState(errors)
            }
        })
    }

    function navigateToSignUp() {
        navigate('/signup')
        window.scrollTo(0, 0)
    }

    function navigateToPasswordReset() {
        navigate('/password-reset')
        window.scrollTo(0, 0)
    }

    if (errorsState.length > 0)
    return (
        <div>
            <Logos />
            <div className="loginContainer">
                <h2>Please Login</h2>
                <form onSubmit={handleSubmit}>
                    <label>Email Address</label>
                    <br/>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <br/>
                    <label>Password</label>
                    <br/>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <br />
                    <button type="submit">Submit</button>
                </form>
                {errorsState}
                <h3>Don't Have an Account?</h3>
                <button onClick={navigateToSignUp}>Signup</button>
                <h3>Forgot Password?</h3>
                <button onClick={navigateToPasswordReset}>Reset It</button>
            </div>
        </div>
    )
    else 
    return (
        <div>
            <Logos />
            <div className="loginContainer">
                <h2>Please Login</h2>
                <form onSubmit={handleSubmit}>
                    <label>Email Address</label>
                    <br/>
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <br/>
                    <label>Password</label>
                    <br/>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
                    <br />
                    <button type="submit">Submit</button>
                </form>
                {errorsState}
                <h3>Don't Have an Account?</h3>
                <button onClick={navigateToSignUp}>Signup</button>
                <h3>Forgot Password?</h3>
                <button onClick={navigateToPasswordReset}>Reset It</button>
            </div>
        </div>
    )
}

export default Login
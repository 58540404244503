import React from "react";

function CurrentSeasonHitterResults({hitterData}) {

    if (hitterData?.elite_rating_percentages_current_season[0]?.number_elite_ratings < 10) {
        return (
            <div className="hitterResultsContainer">
                <h2>{hitterData?.last_10_results_current_season[0]?.name}'s Last {hitterData?.elite_rating_percentages_current_season[0]?.number_elite_ratings} Games with Elite Ratings for the Current Season</h2>
                <table className="eliteResultsTable">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Matchup Rating</th>
                            <th>PA</th>
                            <th>H</th>
                            <th>1B</th>
                            <th>2B</th>
                            <th>HR</th>
                            <th>R</th>
                            <th>RBI</th>
                            <th>Hits, Runs, RBI</th>
                            <th>Total Bases</th>
                            {/* <th>FD Pts</th>
                            <th>DK Pts</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {hitterData?.last_10_results_current_season?.map((stat) => {
                            return (
                                <tr key={stat.date}>
                                    <td>{stat.date}</td>
                                    <td>{stat.rounded_matchup_rating}</td>
                                    <td>{stat.plate_appearances}</td>
                                    <td>{stat.hits}</td>
                                    <td>{stat.singles}</td>
                                    <td>{stat.doubles}</td>
                                    <td>{stat.home_runs}</td>
                                    <td>{stat.runs}</td>
                                    <td>{stat.rbi}</td>
                                    <td>{stat.hits_runs_rbi}</td>
                                    <td>{stat.total_bases}</td>
                                    {/* <td>{stat.fanduel_points}</td>
                                    <td>{stat.draftkings_points}</td> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        )
    }
    else
    return (
        <div className="hitterResultsContainer">
            <h2>{hitterData?.last_10_results_current_season[0]?.name}'s Last 10 Games with Elite Ratings for the Current Season</h2>
            <table>
                <thead className="eliteResultsTable">
                    <tr>
                        <th>Date</th>
                        <th>Matchup Rating</th>
                        <th>PA</th>
                        <th>H</th>
                        <th>1B</th>
                        <th>2B</th>
                        <th>HR</th>
                        <th>R</th>
                        <th>RBI</th>
                        <th>Hits, Runs, RBI</th>
                        <th>Total Bases</th>
                        {/* <th>FD Pts</th>
                        <th>DK Pts</th> */}
                    </tr>
                </thead>
                <tbody>
                    {hitterData?.last_10_results_current_season?.map((stat) => {
                        return (
                            <tr key={stat.date}>
                                <td>{stat.date}</td>
                                <td>{stat.rounded_matchup_rating}</td>
                                <td>{stat.plate_appearances}</td>
                                <td>{stat.hits}</td>
                                <td>{stat.singles}</td>
                                <td>{stat.doubles}</td>
                                <td>{stat.home_runs}</td>
                                <td>{stat.runs}</td>
                                <td>{stat.rbi}</td>
                                <td>{stat.hits_runs_rbi}</td>
                                <td>{stat.total_bases}</td>
                                {/* <td>{stat.fanduel_points}</td>
                                <td>{stat.draftkings_points}</td> */}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default CurrentSeasonHitterResults
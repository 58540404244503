import React, {useContext, useRef} from "react";
import { UserContext } from '../Context/UserContext';
import {useNavigate} from "react-router-dom"

function GameBar() {
    const {state} = useContext(UserContext);
    const navigate = useNavigate()
    const gameBarRef = useRef(null);

    function getAbbreviatedName(fullName) {
        if (fullName === undefined || fullName === "")
            return " "
        else {
            // Split the full name into first name and last name
            const [firstName, lastName] = fullName.split(' ');
            // Get the first initial
                const firstInitial = firstName.charAt(0);
            // Combine the first initial and last name
                return `${firstInitial}. ${lastName}`
        }
    }

    function navigateToSpecificGame(game) {
        navigate(`/game/${game.away_team}/vs/${game.home_team}`)
        window.scrollTo(0, 0)
    }

    function scrollLeft() {
        if (gameBarRef.current) {
            const screenWidth = window.innerWidth
            let scrollDistance = -150; // Adjust as needed
            if (screenWidth <= 600) {
                scrollDistance = -50
            }

            const scrollStep = Math.ceil(Math.abs(scrollDistance) / 50); // Adjust as needed
            const scrollDuration = 150; // Adjust as needed
            const scrollTarget = gameBarRef.current.scrollLeft + scrollDistance;
            const startTime = performance.now();
    
            function scroll(timestamp) {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / scrollDuration, 1);
                gameBarRef.current.scrollLeft += scrollDistance * progress;
                
                if (progress < 1) {
                    requestAnimationFrame(scroll);
                }
            }
    
            requestAnimationFrame(scroll);
        }
    }
    
    function scrollRight() {
        if (gameBarRef.current) {
            let scrollDistance = 150; // Adjust as needed
            const screenWidth = window.innerWidth
            if (screenWidth <= 600) {
                scrollDistance = 50
            }

            const scrollStep = Math.ceil(Math.abs(scrollDistance) / 50); // Adjust as needed
            const scrollDuration = 150; // Adjust as needed
            const scrollTarget = gameBarRef.current.scrollLeft + scrollDistance;
            const startTime = performance.now();
    
            function scroll(timestamp) {
                const elapsed = timestamp - startTime;
                const progress = Math.min(elapsed / scrollDuration, 1);
                gameBarRef.current.scrollLeft += scrollDistance * progress;
                
                if (progress < 1) {
                    requestAnimationFrame(scroll);
                }
            }
    
            requestAnimationFrame(scroll);
        }
    }
    
    if (state.games?.length <= 0)
    return (
        <div></div>
    )
    else
    return (
        <div>
            <h3 id="gameFilter">Game Filter</h3>
            <p id="filterP">Click For Game Specific Information</p>
            <div className="gameBarContainer">
                <button className="scrollButton-left" onClick={scrollLeft}>⬅️</button>
                <div ref={gameBarRef} className="gameBar">
                    {state?.games?.map((game) => {
                        const abbreviatedAwaySP = getAbbreviatedName(game.away_team_SP);
                        const abbreviatedHomeSP = getAbbreviatedName(game.home_team_SP);
                        const awayTeamStyles = {
                            backgroundColor: 
                                (game.away_team === 'ARI' ? 'rgba(167, 25, 48, 0.7)' : '') ||
                                (game.away_team === 'ATL' ? 'rgba(206, 17, 65, 0.7)' : '') ||
                                (game.away_team === 'BAL' ? 'rgba(223, 70, 1, 0.7)' : '') ||
                                (game.away_team === 'BOS' ? 'rgba(189, 48, 57, 0.7)' : '') ||
                                (game.away_team === 'CHC' ? 'rgba(14, 51, 134, 0.7)' : '') ||
                                (game.away_team === 'CHW' ? 'rgba(39, 37, 31, 0.7)' : '') ||
                                (game.away_team === 'CIN' ? 'rgba(198, 1, 31, 0.7)' : '') ||
                                (game.away_team === 'CLE' ? 'rgba(0, 56, 93, 0.7)' : '') ||
                                (game.away_team === 'COL' ? 'rgba(51, 51, 102, 0.7)' : '') ||
                                (game.away_team === 'DET' ? 'rgba(12, 35, 64, 0.7)' : '') ||
                                (game.away_team === 'HOU' ? 'rgba(0, 45, 98, 0.7)' : '') ||
                                (game.away_team === 'KCR' ? 'rgba(0, 70, 135, 0.7)' : '') ||
                                (game.away_team === 'LAA' ? 'rgba(186, 0, 33, 0.7)' : '') ||
                                (game.away_team === 'LAD' ? 'rgba(0, 90, 156, 0.7)' : '') ||
                                (game.away_team === 'MIA' ? 'rgba(0, 163, 224, 0.7)' : '') ||
                                (game.away_team === 'MIL' ? 'rgba(18, 40, 75, 0.7)' : '') ||
                                (game.away_team === 'MIN' ? 'rgba(0, 43, 92, 0.7)' : '') ||
                                (game.away_team === 'NYM' ? 'rgba(0, 45, 114, 0.7)' : '') ||
                                (game.away_team === 'NYY' ? 'rgba(0, 48, 135, 0.7)' : '') ||
                                (game.away_team === 'OAK' ? 'rgba(0, 56, 49, 0.7)' : '') ||
                                (game.away_team === 'PHI' ? 'rgba(232, 24, 40, 0.7)' : '') ||
                                (game.away_team === 'PIT' ? 'rgba(39, 37, 31, 0.7)' : '') ||
                                (game.away_team === 'STL' ? 'rgba(196, 30, 58, 0.7)' : '') ||
                                (game.away_team === 'SDP' ? 'rgba(47, 36, 29, 0.7)' : '') ||
                                (game.away_team === 'SFG' ? 'rgba(253, 90, 30, 0.7)' : '') ||
                                (game.away_team === 'SEA' ? 'rgba(12, 44, 86, 0.7)' : '') ||
                                (game.away_team === 'CIN' ? 'rgba(198, 1, 31, 0.7)' : '') ||
                                (game.away_team === 'CLE' ? 'rgba(0, 56, 93, 0.7)' : '') ||
                                (game.away_team === 'COL' ? 'rgba(51, 51, 102, 0.7)' : '') ||
                                (game.away_team === 'TBR' ? 'rgba(9, 44, 92, 0.7)' : '') ||
                                (game.away_team === 'TEX' ? 'rgba(0, 50, 120, 0.7)' : '') ||
                                (game.away_team === 'TOR' ? 'rgba(19, 74, 142, 0.7)' : '') ||
                                (game.away_team === 'WSN' ? 'rgba(171, 0, 3, 0.7)' : '')
                            };
                        const homeTeamStyles = {
                            backgroundColor: 
                                (game.home_team === 'ARI' ? 'rgba(167, 25, 48, 0.55)' : '') ||
                                (game.home_team === 'ATL' ? 'rgba(206, 17, 65, 0.55)' : '') ||
                                (game.home_team === 'BAL' ? 'rgba(223, 70, 1, 0.55)' : '') ||
                                (game.home_team === 'BOS' ? 'rgba(189, 48, 57, 0.55)' : '') ||
                                (game.home_team === 'CHC' ? 'rgba(14, 51, 134, 0.55)' : '') ||
                                (game.home_team === 'CHW' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                (game.home_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                (game.home_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                (game.home_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                (game.home_team === 'DET' ? 'rgba(12, 35, 64, 0.55)' : '') ||
                                (game.home_team === 'HOU' ? 'rgba(0, 45, 98, 0.55)' : '') ||
                                (game.home_team === 'KCR' ? 'rgba(0, 70, 135, 0.55)' : '') ||
                                (game.home_team === 'LAA' ? 'rgba(186, 0, 33, 0.55)' : '') ||
                                (game.home_team === 'LAD' ? 'rgba(0, 90, 156, 0.55)' : '') ||
                                (game.home_team === 'MIA' ? 'rgba(0, 163, 224, 0.55)' : '') ||
                                (game.home_team === 'MIL' ? 'rgba(18, 40, 75, 0.55)' : '') ||
                                (game.home_team === 'MIN' ? 'rgba(0, 43, 92, 0.55)' : '') ||
                                (game.home_team === 'NYM' ? 'rgba(0, 45, 114, 0.55)' : '') ||
                                (game.home_team === 'NYY' ? 'rgba(0, 48, 135, 0.55)' : '') ||
                                (game.home_team === 'OAK' ? 'rgba(0, 56, 49, 0.55)' : '') ||
                                (game.home_team === 'PHI' ? 'rgba(232, 24, 40, 0.55)' : '') ||
                                (game.home_team === 'PIT' ? 'rgba(39, 37, 31, 0.55)' : '') ||
                                (game.home_team === 'STL' ? 'rgba(196, 30, 58, 0.55)' : '') ||
                                (game.home_team === 'SDP' ? 'rgba(47, 36, 29, 0.55)' : '') ||
                                (game.home_team === 'SFG' ? 'rgba(253, 90, 30, 0.55)' : '') ||
                                (game.home_team === 'SEA' ? 'rgba(12, 44, 86, 0.55)' : '') ||
                                (game.home_team === 'CIN' ? 'rgba(198, 1, 31, 0.55)' : '') ||
                                (game.home_team === 'CLE' ? 'rgba(0, 56, 93, 0.55)' : '') ||
                                (game.home_team === 'COL' ? 'rgba(51, 51, 102, 0.55)' : '') ||
                                (game.home_team === 'TBR' ? 'rgba(9, 44, 92, 0.55)' : '') ||
                                (game.home_team === 'TEX' ? 'rgba(0, 50, 120, 0.55)' : '') ||
                                (game.home_team === 'TOR' ? 'rgba(19, 74, 142, 0.55)' : '') ||
                                (game.home_team === 'WSN' ? 'rgba(171, 0, 3, 0.55)' : '')
                            };
                        return (
                        <ul className='gameCards' key={game.id}>
                            <li onClick={() => navigateToSpecificGame(game)}>
                                <div style={awayTeamStyles}>{`${game.away_team}: ${abbreviatedAwaySP}`}</div>
                                <div style={homeTeamStyles}>{`at ${game.home_team}: ${abbreviatedHomeSP}`}</div>
                            </li>
                        </ul>
                        )
                    })}
                </div>
                <button className="scrollButton-right" onClick={scrollRight}>➡️</button>
            </div>
        </div>
    )
}

export default GameBar
import React from "react";
import logoImage from "../logos/the-batters-box-icon.svg"
import {useNavigate} from "react-router-dom"

function Footer() {
    const navigate = useNavigate()

    function navigateToTerms() {
        navigate('/terms-of-service')
        window.scrollTo(0, 0)
    }

    function navigateToPrivacy() {
        navigate('/privacy-policy')
        window.scrollTo(0, 0)
    }

    return (
        <div className="footerContainer"> 
            <div className="footerLogo">
                <img id="iconLogoFooter" src={logoImage} alt="Logo"></img>
                <p>Batters Box Media Inc.</p>
            </div>
            <div className="contactContainer">
                <p>contactbattersbox@gmail.com</p>
            </div>
            <div className="agreements">
                <p style={{textDecoration: "underline", cursor: "pointer"}} onClick={navigateToTerms}>Terms of Service</p>
                <p style={{textDecoration: "underline", cursor: "pointer"}} onClick={navigateToPrivacy}>Privacy Policy</p>
                <p>Always Bet Responsibly</p>
                <p>Gambling Problem? Call 1-800-GAMBLER</p>
            </div>
        </div>
    )
}

export default Footer
import React from "react";
import {useNavigate} from "react-router-dom"

function GetPremium({className}) {
    const navigate = useNavigate()

    function handlePremiumNavigation() {
        navigate('/checkout')
        window.scrollTo(0, 0)
    }

    return (
        <div className={className}>
            <p>Want more? Upgrade to premium for extra tools and features!</p>
            <button onClick={handlePremiumNavigation}>Get Premium</button>
        </div>
    )
}

export default GetPremium
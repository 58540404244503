import React, {useState} from "react";
import {useParams, useNavigate} from "react-router-dom"
import Logos from "./Logos";

function PasswordResetForm() {
    const {token} = useParams()
    //console.log('token', token)
    const navigate = useNavigate()
    const [password, setPassword] = useState('')
    const [email, setEmail] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [errorsState, setErrorsState] = useState([])
    const [pwStatus, setPWStatus] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        fetch(`/api_password_resets/${token}`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: email,
                password: password,
                password_confirmation: passwordConfirmation,
                token: token
            })
        })
        .then(res => res.json())
        .then(data => {
            //console.log('data on password reset form', data)
            if (!data.error) {
                setPWStatus(true)
            }
            else {
                setPassword("")
                setPasswordConfirmation("")
                const errors = <li className="errors">{data.error}</li>
                setErrorsState(errors)
                //console.log('errorsState', errorsState)
            }
        })
    }

    function navigateToLogin() {
        navigate('/login')
        window.scrollTo(0, 0)
    }

    if (pwStatus) return (
        <div>
            <Logos />
            <div className="resetPWFormContainer">
                <h2>Reset Your Password Here</h2>
                <form onSubmit={handleSubmit}>
                    <label>Email</label>
                    <br />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <label>New Password</label>
                    <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <label>Confirm New Password</label>
                    <br />
                    <input type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <br />
                    <button type="submit">Submit</button>
                </form>
                <h2>Password Reset. Please Login.</h2>
                <button onClick={navigateToLogin}>Login</button>
            </div>
        </div>
    )
    else if (errorsState.length > 0) return (
        <div>
            <Logos />
            <div className="resetPWFormContainer">
                <h2>Reset Your Password Here</h2>
                <form onSubmit={handleSubmit}>
                    <label>Email</label>
                    <br />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <label>New Password</label>
                    <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <label>Confirm New Password</label>
                    <br />
                    <input type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <br />
                    <button type="submit">Submit</button>
                </form>
                {errorsState}
            </div>
        </div>
    )
    else return (
        <div>
            <Logos />
            <div className="resetPWFormContainer">
                <h2>Reset Your Password Here</h2>
                <form onSubmit={handleSubmit}>
                    <label>Email</label>
                    <br />
                    <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
                    <br />
                    <label>New Password</label>
                    <br />
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    <br />
                    <label>Confirm New Password</label>
                    <br />
                    <input type="password" value={passwordConfirmation} onChange={(e) => setPasswordConfirmation(e.target.value)} />
                    <br />
                    <button type="submit">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default PasswordResetForm
import React, {useContext, useState, useEffect, useMemo} from "react";
import { UserContext } from '../Context/UserContext'
import FeatureComparison from "./FeatureComparison";
import SubPassComp from "./SubPassComp";
import CheckoutForm from "./CheckoutForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Logos from "./Logos";
import SignupLoginButtons from "./SignupLoginButtons";

function Checkout() {
    const {state} = useContext(UserContext);
    const [publishableKey, setPublishableKey] = useState(null)

    useEffect(() => {
        const fetchPublishableKey = async () => {
            const response = await fetch('/credentials')
            const data = await response.json()
            setPublishableKey(data.stripe_publishable_key)
        }
        fetchPublishableKey();
    }, [])

    const stripePromise = useMemo(() => {
        if (publishableKey) {
            return loadStripe(publishableKey)
        }
    }, [publishableKey])
    //console.log('stripePromise', stripePromise)
    //console.log('publishablekey', publishableKey)

    const options = {
        mode: 'payment',
        currency: 'usd',
        amount: 50, //STRIPE PAYMENT ELEMENT WILL ERROR OUT IF THIS AMOUNT IS NOT 50, TRIED CHANGING TO 30 AND IT DIDN'T WORK
        //the price will still be correct based on whats on the checkoutform so this 50 amount wont impact the price.
    }

    const hasSub = state.user && state.user.subscription && state.user.subscription.start_date && state.user.subscription.end_date ? true : false
    const today = new Date()
    const isActiveSub = hasSub && today >= new Date(state.user?.subscription?.start_date) && today <= new Date(state.user?.subscription?.end_date)

    if (isActiveSub) {
        return (
            <div>
                <Logos />
                <div className="alreadyPremium">
                    <h2>You're already using an active subscription.</h2>
                    <h3>Having issues? Email us at contactbattersbox@gmail.com.</h3>
                </div>
            </div>
        )
    }
    else if (state?.loggedIn === true)
        return (
            <div>
                <Logos />
                <div className="alreadyPremium">
                    <h2>Premium features are FREE during the World Series with an account. You're good to go!</h2>
                    <h3>Having issues? Email us at contactbattersbox@gmail.com.</h3>
                </div>
                {/* <div className="checkoutContainer">
                    <SubPassComp />
                    {stripePromise && (
                        <Elements stripe={stripePromise} options={options}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </div> */}
            </div>
    )
    else
        return (
            <div>
                <Logos />
                <div className="checkoutNotSignedIn">
                    <br />
                    <h3 style={{textAlign: "center", color: "black", marginTop: "2rem"}}>Premium is FREE for the World Series. Just sign up or login to gain access!</h3>
                    {/* <h1>Please Sign Up or Login</h1> */}
                    <SignupLoginButtons />
                    <h3 style={{textAlign: "center", color: "black"}}>Ability to purchase passes will return for the 2025 season.</h3>
                </div>
                <SubPassComp />
            </div>
        )
}

export default Checkout
import React, {useState, useEffect, useContext} from "react";
import ThreeYearElitePitcherResults from "./ThreeYearElitePitcherResults";
import ThreeYearElitePitcherGraph from "./ThreeYearElitePitcherGraph";
import CurrentSeasonElitePitcherGraph from "./CurrentSeasonElitePitcherGraph";
import CurrentSeasonElitePitcherResults from "./CurrentSeasonElitePitcherResults";
import ThreeYearEliteStrikeoutPitcherResults from "./ThreeYearEliteStrikeoutPitcherResults";
import ThreeYearEliteStrikeoutPitcherGraph from "./ThreeYearEliteStrikeoutPitcherGraph";
import CurrentSeasonEliteStrikeoutPitcherResults from "./CurrentSeasonEliteStrikeoutPitcherResults";
import CurrentSeasonEliteStrikeoutPitcherGraph from "./CurrentSeasonEliteStrikeoutPitcherGraph";
import ThreeYearPoorPitcherResults from "./ThreeYearPoorPitcherResults";
import ThreeYearPoorPitcherGraph from "./ThreeYearPoorPitcherGraph";
import ThreeYearPoorStrikeoutPitcherResults from "./ThreeYearPoorStrikeoutPitcherResults";
import ThreeYearPoorStrikeoutPitcherGraph from "./ThreeYearPoorStrikeoutPitcherGraph";
import CurrentSeasonPoorPitcherGraph from "./CurrentSeasonPoorPitcherGraph";
import CurrentSeasonPoorPitcherResults from "./CurrentSeasonPoorPitcherResults";
import CurrentSeasonPoorStrikeoutPitcherGraph from "./CurrentSeasonPoorStrikeoutPitcherGraph";
import CurrentSeasonPoorStrikeoutPitcherResults from "./CurrentSeasonPoorStrikeoutPitcherResults";
import { UserContext } from '../Context/UserContext';
import NotPremium from "./NotPremium";
import Logos from "./Logos";

function PitcherSearch() {
    const {state} = useContext(UserContext);
    const [pitcherNames, setPitcherNames] = useState([])
    const [pitcherData, setPitcherData] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [oldInputValue, setOldInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [ratingFilter, setRatingFilter] = useState("Default (Last 3 Seasons) Eilte Matchup Ratings")
    //console.log('timeFrame', ratingFilter)

    const isUser = state.loggedIn ? true : false
    // const subscription = state.user.subscription
    // //console.log('sub', subscription)
    // const hasSub = subscription && subscription.start_date && subscription.end_date ? true : false
    // //console.log('hasSub?', hasSub)
    // const today = new Date()
    // const isActiveSub = hasSub && today >= new Date(subscription?.start_date) && today <= new Date(subscription?.end_date)
    //console.log('active sub?', isActiveSub)

    useEffect(() => {
        if (isUser)
        //if (isActiveSub)
        fetch('/pitchers')
            .then(res => res.json())
            .then(data => {
                //console.log('pitcherNameData', data)
                setPitcherNames(data)
            })
    }, [isUser])

    function handleInputChange(e) {
        setInputValue(e.target.value)
    }

    useEffect(() => {
        if (inputValue === "" || inputValue === null || inputValue === undefined) {
            setSuggestions(pitcherNames)
        }
        else {
            setSuggestions(pitcherNames.filter(pitcher => pitcher.toLowerCase().includes(inputValue.toLowerCase())));
        }
    }, [inputValue]);

    function handleSearch(e) {
        e.preventDefault()
        const encodedName = encodeURIComponent(inputValue)
        fetch(`/pitchers/${encodedName}`)
            .then(res => res.json())
            .then(data => {
                if (data) {
                    //console.log('pitcherData', data)
                    setPitcherData(data)
                }
                else {
                    alert("Error: Pitcher not found")
                }
            })
        setOldInputValue(inputValue)
        setInputValue("")
    }

    function handleFilterChange(e) {
        //console.log('ratingFilter', ratingFilter)
        setRatingFilter(e.target.value)
    }

    // const relPitcherDefault = state.dailyPitcherThreeYearStats.filter((stat) => stat.pitcher_name === oldInputValue)
    // console.log('relPitcherDefault', relPitcherDefault)
    // const relPitcherCS = state.dailyPitcherCurrentSeasonStats.filter((stat) => stat.pitcher_name === oldInputValue)

    if (isUser)
    return (
        <div>
            <Logos />
            <div className="hitterTrends">
                <h2>MLB Pitcher Trends</h2>
                <form onSubmit={handleSearch}>
                    <label>Search A Pitcher: </label>
                    <input type="text" placeholder="Ex: Shota Imanaga" value={inputValue} onChange={handleInputChange}></input>
                    <button type="submit">Search</button>
                </form>
                {inputValue !== "" && (
                    <div className="searchSuggestions">
                        <ul>
                            {suggestions.map((suggestion, index) => (
                                <li className="searchLi" key={index} onClick={() => setInputValue(suggestion)}>
                                    {suggestion}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {pitcherData.length !== 0 && (
                    <>
                        <br />
                        {/* <div>
                            <h2>{relPitcherDefault[0]?.pitcher_name}'s Matchup Ratings for Today</h2>
                            <p>Default (Last 3 Seasons): {relPitcherDefault[0]?.pitcher_matchup_rating_thirty_percent}</p>
                            <p>Default Avg Hitter Matchup Strikeout Pct: {relPitcherDefault[0]?.avg_hitter_matchup_k_pct}%</p>
                            <p>Current Season: {relPitcherCS[0]?.pitcher_matchup_rating_thirty_percent}</p>
                            <p>Current Season Avg Hitter Matchup Strikeout Pct: {relPitcherCS[0]?.avg_hitter_matchup_k_pct}%</p>
                        </div>
                        <br /> */}
                        <div className="ratings-dropdown">
                            <label>Select Filter: </label>
                            <select onChange={handleFilterChange}>
                                <option value="Default (Last 3 Seasons) Eilte Matchup Ratings">Default (Last 3 Seasons) Eilte Matchup Ratings</option>
                                <option value="Default (Last 3 Seasons) Poor Matchup Ratings">Default (Last 3 Seasons) Poor Matchup Ratings</option>
                                <option value="Current Season Only Elite Matchup Ratings">Current Season Only Elite Matchup Ratings</option>
                                <option value="Current Season Only Poor Matchup Ratings">Current Season Only Poor Matchup Ratings</option>
                                <option value="Default (Last 3 Seasons) Elite Avg Hitter Matchup Strikeout Pct">Default (Last 3 Seasons) Elite Avg Hitter Matchup Strikeout Pct</option>
                                <option value="Default (Last 3 Seasons) Poor Avg Hitter Matchup Strikeout Pct">Default (Last 3 Seasons) Poor Avg Hitter Matchup Strikeout Pct</option>
                                <option value="Current Season Only Elite Avg Hitter Matchup Strikeout Pct">Current Season Only Elite Avg Hitter Matchup Strikeout Pct</option>
                                <option value="Current Season Only Poor Avg Hitter Matchup Strikeout Pct">Current Season Only Poor Avg Hitter Matchup Strikeout Pct</option>
                            </select>
                        </div>
                    </>
                )}
                {pitcherData?.last_10_elite_results_three_year_matchup_rating?.length > 0 && ratingFilter === "Default (Last 3 Seasons) Eilte Matchup Ratings" && (
                    <>
                        <ThreeYearElitePitcherResults pitcherData={pitcherData} />
                        <ThreeYearElitePitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_elite_results_three_year_matchup_rating?.length === 0 && ratingFilter === "Default (Last 3 Seasons) Eilte Matchup Ratings" && (
                    <>
                        <h2>No Default Elite Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_poor_results_three_year_matchup_rating?.length > 0 && ratingFilter === "Default (Last 3 Seasons) Poor Matchup Ratings" && (
                    <>
                        <ThreeYearPoorPitcherResults pitcherData={pitcherData}/>
                        <ThreeYearPoorPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_poor_results_three_year_matchup_rating?.length === 0 && ratingFilter === "Default (Last 3 Seasons) Poor Matchup Ratings" && (
                    <>
                        <h2>No Default Poor Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_elite_results_three_year_strikeouts?.length > 0 && ratingFilter === "Default (Last 3 Seasons) Elite Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <ThreeYearEliteStrikeoutPitcherResults pitcherData={pitcherData}/>
                        <ThreeYearEliteStrikeoutPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_elite_results_three_year_strikeouts?.length === 0 && ratingFilter === "Default (Last 3 Seasons) Elite Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <h2>No Default Elite Avg Hitter Matchup Strikeout Pct Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_poor_results_three_year_strikeouts?.length > 0 && ratingFilter === "Default (Last 3 Seasons) Poor Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <ThreeYearPoorStrikeoutPitcherResults pitcherData={pitcherData}/>
                        <ThreeYearPoorStrikeoutPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_poor_results_three_year_strikeouts?.length === 0 && ratingFilter === "Default (Last 3 Seasons) Poor Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <h2>No Default Poor Avg Hitter Matchup Strikeout Pct Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_elite_results_current_season_matchup_rating?.length > 0 && ratingFilter === "Current Season Only Elite Matchup Ratings" && (
                    <>
                        <CurrentSeasonElitePitcherResults pitcherData={pitcherData} />
                        <CurrentSeasonElitePitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_elite_results_current_season_matchup_rating?.length === 0 && ratingFilter === "Current Season Only Elite Matchup Ratings" && (
                    <>
                        <h2>No Current Season Elite Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_poor_results_current_season_matchup_rating?.length > 0 && ratingFilter === "Current Season Only Poor Matchup Ratings" && (
                    <>
                        <CurrentSeasonPoorPitcherResults pitcherData={pitcherData} />
                        <CurrentSeasonPoorPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_poor_results_current_season_matchup_rating?.length === 0 && ratingFilter === "Current Season Only Poor Matchup Ratings" && (
                    <>
                        <h2>No Current Season Poor Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_elite_results_current_season_strikeouts?.length > 0 && ratingFilter === "Current Season Only Elite Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <CurrentSeasonEliteStrikeoutPitcherResults pitcherData={pitcherData} />
                        <CurrentSeasonEliteStrikeoutPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_elite_results_current_season_strikeouts?.length === 0 && ratingFilter === "Current Season Only Elite Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <h2>No Current Season Elite Avg Hitter Matchup Strikeout Pct Ratings for {oldInputValue}</h2>
                    </>
                )}
                {pitcherData?.last_10_poor_results_current_season_strikeouts?.length > 0 && ratingFilter === "Current Season Only Poor Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <CurrentSeasonPoorStrikeoutPitcherResults pitcherData={pitcherData} />
                        <CurrentSeasonPoorStrikeoutPitcherGraph pitcherData={pitcherData}/>
                    </>
                )}
                {pitcherData?.last_10_poor_results_current_season_strikeouts?.length === 0 && ratingFilter === "Current Season Only Poor Avg Hitter Matchup Strikeout Pct" && (
                    <>
                        <h2>No Current Season Poor Avg Hitter Matchup Strikeout Pct Ratings for {oldInputValue}</h2>
                    </>
                )}
            </div>
        </div>
    )
    else {
        return (
            <div className="userNotPremium">
                <NotPremium />
            </div>
        )
    }
}

export default PitcherSearch
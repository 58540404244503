import React from "react";
import {useNavigate} from "react-router-dom"
import GetPremium from "./GetPremium";
import TableImage from "../images/septemer 2023 random top 10 snip.jpg"
import NewFeaturesImage from "../images/2024.png"

function Highlights2023() {
    const navigate = useNavigate()

    function navigateToBreakdown() {
        navigate('/guide')
        window.scrollTo(0, 0)
    }

    function navigateToNewFeatures() {
        navigate('/new-features')
        window.scrollTo(0, 0)
    }

    return (
        <div className="highlightsContainer">
            <br />
            <GetPremium className={"getPremiumContainer"}/>
            <h2>2023 Highlights</h2>
            <h3 id="highlightsDescription">Recapping the top players and best moments from our first season</h3>
            <p>Even if you don't like to play daily fantasy or bet on baseball, following along with the ratings is fun. Here were some of the best moments from the season and my top 10 players from 2023.</p>
            <h3>Favorite Moments</h3>
            <p className="paragraphHeader">1. All Rise on August 23rd</p>
            <p>Aaron Judge's 0.603 matchup rating was the highest of the day, going up against lefty MacKenzie Gore. Judge wasted no time, homering off Gore in his first two at-bats with the second being a grand slam. Both were crushed, going over 430 feet at Yankee Stadium. He would go on to hit a third home run in the 7th inning as well for his first career three homer game.</p>
            <p className="paragraphHeader">2. Lucky Number 13</p>
            <p>Those that were following along in 2023 know that I typically would tweet out how well the top 10 performed the day before. Typically, seven or eight of the top 10 would at least get a hit. However, on July 30th, each of the top 13 recorded a hit, with 10 of the 13 going over 1.5 hits, runs and RBI and 9 going over 1.5 total bases. An incredible day. I'd love to know what the odds on that 13 leg hit parlay would have been.</p>
            <p className="paragraphHeader">3. Back-to-Back-to-Back-to-Back</p>
            <p>Everybody loves the longball, especially one after another. From August 21 - 24, the hitter with the highest matchup rating went yard. It started with Zack Gelof going deep off Alec Marsh at the Coliseum. Brandon Belt waited until the 10th inning, turning around a fastball into the stands. Then Aaron Judge had his three home run day that I mentioned above before coming through again on August 24th. He had an even higher matchup rating (0.712) when he took fan favorite Patrick Corbin deep to center field. A very fun four day stretch in the heat of summer.</p>
            <p className="paragraphHeader">Honorable Mention:</p>
            <p>On two separate occasions, the hitter with the highest rating hit a double or a home run 5 days in a row. Get ready for more streaks like this in 2024!</p>
            <h3>2023 Top 10 Players</h3>
            <p>Now I want to share my top 10 players from the 2023 season based on how well they performed in relation to their elite ratings. I measured each hitter based on how high they finished among all hitters with at least 10 elite ratings. If a hitter has the highest over 1.5 total bases rate, that means of all hitters with at least 10 elite ratings, he went over 1.5 total bases more often than anyone else.</p>
            <ul>
                <li>10. Evan Carter (Rangers OF)</li>
                <p>The rookie sensation came out firing on all cylinders. His small sample size had him among the elite rated hitters almost every day but he kept coming through. With his small sample, he finished the season with the fourth highest average elite rating (0.614). He also finished inside the top 10 in two key categories: 7th highest HR rate (33.33%) and T-10th highest over 1.5 total bases rate (58.33%).</p>
                <li>9. Alec Bohm (Phillies INF)</li>
                <p>The emerging star continued his improvement with a career-high in doubles (31), home runs (20) and RBI (97). With this career year it's no surprise he finished inside the top 10 in two key areas as well: T-7th in over 1.5 hits, runs and RBI rate (70%) and T-7th in over 1.5 total bases rate (60%).</p>
                <li>8. Ketel Marte (Diamondbacks 2B)</li>
                <p>2023 was one of the best years for Marte, recording the second most hits, home runs, runs and RBI of his career. He came through for us more often than many last year when he had an elite rating. He had the 5th highest over 1.5 hits, runs and RBI rate (71.43%) and T-4th highest hit rate (85.71%).</p>
                <li>7. Trea Turner (Phillies SS)</li>
                <p>A bit of a down year for Turner's standards but the second Philly worthy of praise. He was easily among the best in a couple categories when he had an elite rating. He finished with the 5th highest over 1.5 total bases rate (62.5%) and the 3rd highest over 1.5 hits, runs and RBI rate (75%).</p>
                <li>6. Brent Rooker (Athletics DH/OF)</li>
                <p>He earned his first trip to the All-Star game after a career-high 30 home runs. The long balls are a big reason for him making my top 10. He finished with the 3rd highest home run rate (40%), T-7th highest over 1.5 total bases rate (60%) and T-7th highest over 1.5 hits, runs and RBI rate (70%).</p>
                <li>5. Ronald Acuna Jr. (Braves OF)</li>
                <p>You may think this is too low for the reigning NL MVP but remember the criteria is based on elite ratings accuracy. He finished inside the top 10 in three categories: 6th highest over 1.5 hits, runs and RBI rate (70.83%), 7th highest hit rate (83.33%) and T-10th highest over 1.5 total bases rate (58.33%).</p>
                <li>4. Austin Riley (Braves 3B)</li>
                <p>Three straight seasons of 30-plus homers and 90-plus RBI has Riley among the league's best hitters. He finished with the 9th highest over 1.5 hits, runs and RBI rate (66.67%), the 2nd highest over 1.5 total bases rate (70.83%) and the highest home run rate (41.67%).</p>
                <li>3. Ozzie Albies (Braves 2B)</li>
                <p>Sick of the Braves yet? Albies ends the run of players from Atlanta and the NL East. He finished inside the top five among three of the measured categories: T-4th highest hit rate (85.71%), 4th highest over 1.5 total bases rate (64.29%) and the highest over 1.5 hits, runs and RBI rate (79.57%). Recording at least two hits, runs and RBI almost 80% of the time with an elite rating is unreal. I'm looking forward to taking advantage of this trend in 2024.</p>
                <li>2. William Contreras (Brewers C/DH)</li>
                <p>I'm sure this comes as a surprise for many but Contreras undoubtedly took advantage of his first season as an every day player. He won his first career silver slugger award and finished 11th in MVP voting. He also finished top 5 in three key areas: 4th highest over 1.5 hits, runs and RBI rate (73.68%), 3rd highest hit rate (89.47%) and the highest over 1.5 total bases rate (73.68%). Don't sleep on the younger Contreras brother.</p>
                <li>1. Shohei Ohtani (Dodgers DH)</li>
                <p>Last but not least, the GOAT. The 2-time AL MVP could not be stopped at the dish last year. He also finished in the top 5 in three areas: 4th highest home run rate (36.59%), 3rd highest over 1.5 total bases rate (65.85%) and the 2nd highest over 1.5 hits, runs and RBI rate (75.61%). Showtime continues to show up and show out.</p>
            </ul>
            <p>Author: Mike Montgomery <a href="https://twitter.com/mikemonty22" target="_blank" rel='noreferrer'>
                    <img className="twitterLogo" src="https://cdn1.iconfinder.com/data/icons/logotypes/32/twitter-512.png" alt=""/>
            </a></p>
            <div className="defaultArticlesContainer">
                <div className="newBreakdownContainer" onClick={navigateToBreakdown}>
                    <h2>Batters Box Breakdown</h2>
                    <img id="tableImage" src={TableImage} alt="hitter table"></img>
                    <p>An In-Depth Guide and How to Use the Ratings</p>
                </div>
                <div className="homePreviewsArticle" onClick={navigateToNewFeatures}>
                    <h2>What's New?</h2>
                    <img id="newFeaturesImage" src={NewFeaturesImage} alt="New 2024 Features"></img>
                    <p>Taking a look at the plethora of new features for 2024</p>
                </div>
            </div>
        </div>
    )
}

export default Highlights2023
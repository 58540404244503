import React, {useState} from "react";
import { NavLink } from "react-router-dom";

function NavBar() {
    const [ratingSubMenuOpen, setRatingSubMenuOpen] = useState(false);
    const [trendSubMenuOpen, setTrendSubMenuOpen] = useState(false)

    const toggleRatingSubMenu = () => {
        setRatingSubMenuOpen(!ratingSubMenuOpen);
    };

    function handleTrendSubMenu() {
        setTrendSubMenuOpen(!trendSubMenuOpen)
    }

    return (
        <nav className="nav">
            <NavLink to="/" className="navLink">
                Home
            </NavLink>
            <div className="navLink" style={{position: 'relative'}}>
                <NavLink className="navLink" onClick={toggleRatingSubMenu}>
                    Ratings
                </NavLink>
                {ratingSubMenuOpen && (
                    <div className="subMenu">
                        <NavLink to="/ratings/hitters" className="subNavLink">
                            Hitters
                        </NavLink>
                        <NavLink to="/ratings/pitchers" className="subNavLink">
                            Pitchers
                        </NavLink>
                    </div>
                )}
            </div>
            <div className="navLink" style={{position: 'relative'}}>
                <NavLink className="navLink" onClick={handleTrendSubMenu}>
                    Trends
                </NavLink>
                {trendSubMenuOpen && (
                    <div className="subMenu">
                        <NavLink to="/trends/hitters" className="subNavLink"> 
                            Hitters
                        </NavLink>
                        <NavLink to="/trends/pitchers" className="subNavLink"> 
                            Pitchers
                        </NavLink>
                    </div>
                )}
            </div>
            {/* <NavLink to="/picks" className="navLink">
                Picks
            </NavLink> */}
            <NavLink to="/about" className="navLink">
                About
            </NavLink>
            <NavLink to="/FAQs" className="navLink">
                Help
            </NavLink>
        </nav>
    )

}

export default NavBar